import { AuthService } from "@forcrowd/backbone-client-core";
var AccountOverviewComponent = /** @class */ (function () {
    function AccountOverviewComponent(authService) {
        this.authService = authService;
    }
    Object.defineProperty(AccountOverviewComponent.prototype, "currentUser", {
        get: function () {
            return this.authService.currentUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountOverviewComponent.prototype, "displayConfirmEmail", {
        get: function () {
            return !(this.currentUser.EmailConfirmed
                || (this.currentUser.Roles[0].Role.Name === "Guest"
                    && !this.currentUser.EmailConfirmationSentOn));
        },
        enumerable: true,
        configurable: true
    });
    return AccountOverviewComponent;
}());
export { AccountOverviewComponent };
