import { OnDestroy, OnInit } from "@angular/core";
import { AuthService, ElementFieldDataType } from "@forcrowd/backbone-client-core";
import { Subject } from "rxjs";
import { mergeMap, debounceTime } from "rxjs/operators";
import { RatingMode } from "../entities/project";
import { AppProjectService } from "../app-project.service";
import { ChartConfig, ChartDataItem } from "../../ng-chart/ng-chart.module";
var ProjectViewerComponent = /** @class */ (function () {
    function ProjectViewerComponent(authService, projectService) {
        this.authService = authService;
        this.projectService = projectService;
        this.config = { projectId: 0 };
        this.chartConfig = null;
        this.currentUser = null;
        this.displayChart = false;
        this.displayDescription = false;
        this.displayIndexDetails = false;
        this.elementFieldDataType = ElementFieldDataType;
        this.elementItemsSortField = "name";
        this.errorMessage = "";
        this.ratingMode = RatingMode;
        this.project = null;
        this.projectId = 0;
        this.saveStream = new Subject();
        this.subscriptions = [];
        this.username = "";
        this.fields = {
            selectedElement: null,
        };
    }
    Object.defineProperty(ProjectViewerComponent.prototype, "isBusy", {
        get: function () {
            return this.projectService.isBusy;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProjectViewerComponent.prototype, "selectedElement", {
        get: function () {
            return this.fields.selectedElement;
        },
        set: function (value) {
            if (this.fields.selectedElement !== value) {
                this.fields.selectedElement = value;
                this.loadChartData();
            }
        },
        enumerable: true,
        configurable: true
    });
    ProjectViewerComponent.prototype.initialize = function (projectId, user) {
        var _this = this;
        // If there is no change, no need to continue
        if (this.projectId === projectId && this.currentUser === user) {
            return;
        }
        this.projectId = projectId;
        this.currentUser = user;
        // Clear previous error messages
        this.errorMessage = "";
        // Validate
        if (!this.projectId) {
            this.errorMessage = "Project id cannot be null";
            return;
        }
        // Get project
        this.projectService.getProjectExpanded(this.config.projectId)
            .subscribe(function (project) {
            if (!project) {
                _this.errorMessage = "Invalid project";
                return;
            }
            _this.project = project;
            // It returns an array, set the first item in the list
            //this.project = project.ElementSet.find(element => element.Id === this.config.Id);
            // Set Initial value + setIncome()
            _this.project.initialValue = _this.config.initialValue || 100;
            _this.project.ElementSet.forEach(function (element) {
                element.ElementFieldSet.forEach(function (field) {
                    field.setIncome();
                });
            });
            // Rating mode updated event
            // TODO: Unsubscribe?
            _this.project.ratingModeUpdated.subscribe(function () { return _this.updateElementItemsSortField(); });
            // Selected element
            _this.selectedElement = _this.project.ElementSet[0];
            _this.loadChartData();
        });
    };
    ProjectViewerComponent.prototype.loadChartData = function () {
        var element = this.selectedElement;
        if (!element) {
            return;
        }
        // Item length check
        if (element.ElementItemSet.length > 20) {
            return;
        }
        if (!this.displayIndexDetails) {
            // TODO Check this rule?
            if (element === element.familyTree()[0] && element.income() > 0) {
                var options = {
                    title: { text: element.Name },
                    chart: { type: "column" },
                    yAxis: {
                        title: { text: "Total Income" }
                    }
                };
                var data_1 = [];
                element.ElementItemSet.forEach(function (elementItem) {
                    data_1.push(new ChartDataItem(elementItem.Name, elementItem.income(), elementItem.incomeUpdated));
                });
                this.chartConfig = new ChartConfig(options, data_1);
            }
            else {
                var options = {
                    title: { text: element.Name },
                    chart: { type: "pie" }
                };
                var data_2 = [];
                element.ElementItemSet.forEach(function (elementItem) {
                    elementItem.ElementCellSet.forEach(function (elementCell) {
                        if (elementCell.ElementField.RatingEnabled) {
                            data_2.push(new ChartDataItem(elementCell.ElementItem.Name, +elementCell.decimalValue().toFixed(2), elementCell.decimalValueUpdated));
                        }
                    });
                });
                this.chartConfig = new ChartConfig(options, data_2);
            }
        }
        else {
            var options = {
                title: { text: "Indexes" },
                chart: { type: "pie" }
            };
            var data_3 = [];
            element.elementFieldSet()
                .forEach(function (field) {
                data_3.push(new ChartDataItem(field.Name, +field.rating().toFixed(2), field.ratingUpdated));
            });
            this.chartConfig = new ChartConfig(options, data_3);
        }
    };
    ProjectViewerComponent.prototype.ngOnDestroy = function () {
        for (var i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    };
    ProjectViewerComponent.prototype.ngOnInit = function () {
        var _this = this;
        var projectId = this.config.projectId || 0;
        // Delayed save operation
        this.saveStream.pipe(debounceTime(1500), mergeMap(function () { return _this.projectService.saveChanges(); })).subscribe();
        // Event handlers
        this.subscriptions.push(this.authService.currentUserChanged.subscribe(function (newUser) {
            return _this.initialize(_this.projectId, newUser);
        }));
        this.initialize(projectId, this.authService.currentUser);
    };
    ProjectViewerComponent.prototype.toggleDescription = function () {
        this.displayDescription = !this.displayDescription;
    };
    // Index Details
    ProjectViewerComponent.prototype.toggleIndexDetails = function () {
        this.displayIndexDetails = !this.displayIndexDetails;
        this.loadChartData();
    };
    ProjectViewerComponent.prototype.updateCellDecimalValue = function (cell, value) {
        this.projectService.updateElementCellDecimalValue(cell, value);
        this.saveStream.next();
    };
    ProjectViewerComponent.prototype.updateElementFieldRating = function (elementField, rating) {
        this.projectService.updateElementFieldRating(elementField, rating);
        this.saveStream.next();
    };
    ProjectViewerComponent.prototype.updateElementItemsSortField = function () {
        this.elementItemsSortField = this.project.RatingMode === RatingMode.CurrentUser
            ? "name"
            : "income";
    };
    return ProjectViewerComponent;
}());
export { ProjectViewerComponent };
