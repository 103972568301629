<div class="row">
  <div class="col-md-12">
    <h3>
      All in One
    </h3>
    <p>
      In the following example, you can see all items in one place.
      Your ratings from previous examples have already been copied to this example.
      Please set your final ratings for each index, which will allow us to compare the results with the current system.
    </p>
    <p>
      As an addition, since this time there are two indexes, their priority within the pool also need to be set.
      <strong>Indexes</strong> view allows us to compare and rate each index within the pool.
    </p>
    <p>
      Here is a quick summary of the indexes:
    </p>
    <ul>
      <li>
        <strong>Priority Index:</strong>
        What type of issues that the organization tries to solve, how fundamental they are? Are they about our basics needs or the luxury ones?
      </li>
      <li>
        <strong>Knowledge Index:</strong>
        Does the organization share its knowledge and contributes to our common knowledge or hides it to itself?
      </li>
      <!--<li>
          <strong>Total Cost Index:</strong>
          How much the organization costs to the society to provide its products, services?
          In other words, how much the organization is profit oriented.
      </li>-->
    </ul>
    Use the following steps to rate the indexes based on their importance and set how much an index should control the amount in the pool.
    <ul>
      <li>
        Click on <code>Total Income</code> link and switch to <strong>Indexes</strong> view to see the details.
      </li>
      <li>
        Rate indexes based on their importance by using <code>+</code> and <code>-</code> buttons at the bottom of them and see the difference between the index income fields.
      </li>
      <li>
        Click to <code>Total Income</code> link again to switch back to initial view.
      </li>
    </ul>
    <!--<p>
        Keep synced with Priority and Knowledge Index samples <input type="checkbox" [(ngModel)]="syncFlag" />
    </p>-->
    <project-viewer [config]="allInOneConfig"></project-viewer>
  </div>
</div>
