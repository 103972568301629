import { timer as observableTimer, Subscription } from "rxjs";
import { OnDestroy, OnInit } from "@angular/core";
import { ChartConfig } from "./chart-config";
var NgChartComponent = /** @class */ (function () {
    function NgChartComponent() {
        this.chartSize = { height: 0, width: 0 };
        this.container = new Container();
        this.displayChart = false;
        this.fields = {
            config: null,
        };
    }
    Object.defineProperty(NgChartComponent.prototype, "config", {
        get: function () {
            return this.fields.config;
        },
        set: function (value) {
            if (this.fields.config === value) {
                return;
            }
            this.fields.config = value;
            this.init();
        },
        enumerable: true,
        configurable: true
    });
    NgChartComponent.prototype.ngOnInit = function () {
        this.init();
    };
    NgChartComponent.prototype.saveChartObject = function (instance) {
        this.container.chartObject = instance;
    };
    NgChartComponent.prototype.init = function () {
        // Todo After new config, it needs to be redrawn but chart.redraw didn't work
        // In order to recreate it, displayChart will be false and the chart will be removed,
        // second div with the same size will appear to keep the screen intact,
        // and new chart will be created in async way by using timer()
        // There are other functions like addAxis, addSeries etc., probably they're going to help
        // But before that, there is a new version of highcharts / coni2k - 15 Dec. '16
        var _this = this;
        if (this.container.chartObject) {
            this.chartSize.height = this.container.chartObject.chartHeight;
            this.chartSize.width = this.container.chartObject.chartWidth;
        }
        this.displayChart = false;
        observableTimer().subscribe(function () {
            _this.container.config = _this.config;
            _this.displayChart = true;
        });
    };
    return NgChartComponent;
}());
export { NgChartComponent };
var Container = /** @class */ (function () {
    function Container() {
        this._config = null;
        this.chartObject = null;
        this.subscriptions = [];
    }
    Object.defineProperty(Container.prototype, "config", {
        get: function () {
            return this._config;
        },
        set: function (value) {
            // Todo Valiations?
            var _this = this;
            if (this._config === value) {
                return;
            }
            // Clean-up previous subscriptions
            for (var i = 0; i < this.subscriptions.length; i++) {
                this.subscriptions[i].unsubscribe();
            }
            this.subscriptions = [];
            // New config
            this._config = value;
            // Convert config.data to config.options.series, and subscribe to updated$ events for further changes
            if (this._config.options.chart.type === "column") {
                this._config.data.forEach(function (dataItem) {
                    var columnChartItem = new ColumnChartItem(dataItem.name, dataItem.value, +_this._config.options.series.length);
                    _this._config.options.series.push(columnChartItem.chartItem);
                    // Event handlers
                    if (dataItem.valueUpdated) {
                        _this.subscriptions.push(dataItem.valueUpdated.subscribe(function (updatedValue) {
                            if (_this.chartObject !== null) {
                                _this.chartObject.series[columnChartItem.index].data[0].update(updatedValue);
                            }
                        }));
                    }
                });
            }
            else if (this._config.options.chart.type === "pie") {
                this._config.data.forEach(function (dataItem) {
                    var pieChartItem = new PieChartItem(dataItem.name, dataItem.value, +_this._config.options.series[0].data.length);
                    _this._config.options.series[0].data.push(pieChartItem.chartItem);
                    // Event handlers
                    if (dataItem.valueUpdated) {
                        _this.subscriptions.push(dataItem.valueUpdated.subscribe(function (updatedValue) {
                            if (_this.chartObject !== null) {
                                _this.chartObject.series[0].data[pieChartItem.index].update(updatedValue);
                            }
                        }));
                    }
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Container.prototype.ngOnDestroy = function () {
        for (var i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    };
    return Container;
}());
var ColumnChartItem = /** @class */ (function () {
    function ColumnChartItem(name, value, index) {
        this.name = name;
        this.value = value;
        this.index = index;
        this.chartItem = {
            name: "",
            data: []
        };
        this.chartItem.name = name;
        this.chartItem.data.push(value);
    }
    return ColumnChartItem;
}());
var PieChartItem = /** @class */ (function () {
    function PieChartItem(name, value, index) {
        this.name = name;
        this.value = value;
        this.index = index;
        this.chartItem = {
            name: "",
            y: 0
        };
        this.chartItem.name = name;
        this.chartItem.y = value;
    }
    return PieChartItem;
}());
