<div class="body">
  <nav class="navbar navbar-inverse navbar-fixed-top">
    <div class="container">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <button (click)="navigate('/')" type="button" class="btn-link navbar-brand">
          Wealth Framework
        </button>
      </div>
      <div id="navbar" class="navbar-collapse collapse">
        <ul *ngIf="currentUser" class="nav navbar-nav navbar-text navbar-right">
          <li [hidden]="currentUser.isAuthenticated()">
            <button (click)="navigate('/app/account/register')" type="button" class="btn-link">
              Register
            </button>
          </li>
          <li [hidden]="currentUser.isAuthenticated()">
            <button (click)="navigate('/app/account/login')" type="button" class="btn-link">
              Login
            </button>
          </li>
          <!--<li [hidden]="!currentUser.isAuthenticated() || !currentUser.IsAnonymous">-->
          <li [hidden]="true">
            <button (click)="showGuestAccountInfoBox()" type="button" class="btn-link">
              <span class="fa fa-info-circle fa-lg" aria-hidden="true"></span>
            </button>
          </li>
          <li [hidden]="!currentUser.isAuthenticated()">
            <button (click)="navigate('/app/account')" type="button" class="btn-link">
              Account
            </button>
          </li>
          <li [hidden]="!currentUser.isAuthenticated()">
            <button (click)="logout()" type="button" class="btn-link">
              Logout
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div class="container body-content">

    <!-- Guest account info block -->
    <div class="g-padding-20 g-pb-0" [hidden]="hideGuestAccountInfoBox">
      <!--<div class="modal-header">
          <h3 class="modal-title">Guest Account</h3>
      </div>-->
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 g-mb-20">
            <p>
              A guest account has been created for you to save your changes temporarily.<br />
            </p>
          </div>
          <div class="col-md-6 text-center">
            <p class="g-mb-30">
              Would like to keep this account?<br />
              Please update your email address.
            </p>
            <p>
              <a class="btn btn-primary btn-block" routerLink="/app/account/change-email" (click)="closeGuestAccountInfoBox()">Change email</a>
            </p>
          </div>
          <div class="col-md-6 text-center">
            <p class="g-mb-30">
              Do you have an existing account?<br />
              Please login with your credentials.
            </p>
            <p>
              <a class="btn btn-primary btn-block" routerLink="/app/account/login" (click)="closeGuestAccountInfoBox()">Login</a>
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-default" type="button" (click)="closeGuestAccountInfoBox()">Skip for now</button>
      </div>
    </div>
    <!-- Guest account info block end -->

    <router-outlet></router-outlet>

    <toaster-container [toasterconfig]="toasterConfig"></toaster-container>

  </div>

  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="contacts pull-left">
            <a href="http://forcrowd.org" target="_blank">
              <img src="assets/images/forCrowd_Logo_142x30.jpg?v=0.65.0" alt="Logo" />
            </a>
            <a href="https://twitter.com/forCrowd" target="_blank" class="g-ml-40">
              <i class="fa fa-twitter fa-lg" aria-hidden="true"></i>
            </a>
            <a href="https://github.com/forCrowd/WealthFramework" target="_blank" class="g-ml-20">
              <i class="fa fa-github fa-lg" aria-hidden="true"></i>
            </a>
            <a href="mailto:contact.wealth@forcrowd.org" target="_blank" class="g-ml-20">
              <i class="fa fa-envelope fa-lg" aria-hidden="true"></i>
            </a>
          </div>
          <div class="pull-right">
            <a routerLink="/app/contributors">Contributors</a><br />
            <small>Version: {{ appVersion }}</small>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>
