<!--
    This is an experimental attempt to help you to get familiar with our project and make your first pull request.
    Follow this tutorial to get started: https://github.com/forCrowd/WealthFramework/wiki/First-Mission

    <tr>
        <td>
            <span>[Your name]</span>
            <span class="nickname">[Your Github username]</span>
        </td>
        <td>[Role]</td>
        <td>[Skills]</td>
        <td>
            {{ getExperienceYears([Year]) }}
        </td>
        <td>
            <ul class="list-inline">
                <li>
                    <a href="[Link]" target="_blank"><span class="fa fa-[Related Font Awesome icon] fa-lg" aria-hidden="true"></span></a>
                </li>
            </ul>
        </td>
        <td>
            {{ getJoinedOnDate([day], [month], [year]) | amTimeAgo }}
        </td>
    </tr>

-->
<div class="row">
  <div class="col-md-12">
    <h2>
      Contributors
    </h2>
    <!--<div class="bg-info g-mb-30">
      <p>
        This is an experimental attempt to help you to get familiar with our project and make your first pull request.<br />
      </p>
      <p>
        Follow this tutorial to get started:
        <a href="https://github.com/forCrowd/WealthFramework/wiki/First-Mission" target="_blank">
          First Mission
        </a>
      </p>
    </div>-->
    <p class="g-mb-30">
      Here is the list of contributors to this project.
    </p>
    <div class="table-contributors">
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>
                Name
              </th>
              <th>
                Role
              </th>
              <th>
                Skills
              </th>
              <th>
                Experience
              </th>
              <th>
                Contacts
              </th>
              <th>
                Joined on
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
                <td>
                  <span>Avni Onur Pehlivan</span>
                  <span class="nickname">Augustpi</span>
                </td>
                <td>Software Developer</td>
                <td>Angular, Angular Dart, Dart, Flutter, JavaScript, NodeJs, MySQL</td>
                <td>
                  {{ getExperienceYears(2013) }}
                </td>
                <td>
                  <ul class="list-inline">
                    <li>
                      <a href="https://www.linkedin.com/in/avni-onur-pehlivan-5005b018/" target="_blank"><span class="fa fa-linkedin fa-lg" aria-hidden="true"></span></a>
                    </li>
                    <li>
                      <a href="mailto:onur.pehlivan@addtohere.com" target="_blank"><span class="fa fa-envelope fa-lg" aria-hidden="true"></span></a>
                    </li>
                    <li>
                      <a href="http://addtohere.com" target="_blank"><span class="fa fa-globe fa-lg" aria-hidden="true"></span></a>
                    </li>
                  </ul>
                </td>
                <td>
                  {{ getJoinedOnDate(18, 9, 2018) | amTimeAgo }}
                </td>
              </tr>
            <tr>
              <td>
                <span>
                  Sanjith Edward
                </span>
                <span class="nickname">
                  Chris
                </span>
              </td>
              <td>
                Software Engineer
              </td>
              <td>
                JavaScript, .NET, HTML, SASS
              </td>
              <td>
                {{ getExperienceYears(2013) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://www.linkedin.com/in/sanjith-edward-88289646/" target="_blank">
                      <span class="fa fa-linkedin fa-lg" aria-hidden="true"></span>
                    </a>
                  </li>
                  <li>
                    <a href="https://github.com/chrised123" target="_blank">
                      <span class="fa fa-github fa-lg" aria-hidden="true"></span>
                    </a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(4, 1, 2018) | amTimeAgo }}
              </td>
            </tr>
            <tr>
              <td>
                <span>Buğra Sitemkar</span>
                <span class="nickname">bugrasitemkar</span>
              </td>
              <td>Software Consultant</td>
              <td>C#, .NET, MVC</td>
              <td>
                {{ getExperienceYears(2012) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://github.com/bugrasitemkar" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(5, 12, 2017) | amTimeAgo }}
              </td>
            </tr>
            <tr>
              <td>
                <span>Erin Nakano</span>
                <span class="nickname">nakanoer</span>
              </td>
              <td>Student</td>
              <td>CSS, HTML</td>
              <td>
                {{ getExperienceYears(2017) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://github.com/nakanoer" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(2, 12, 2017) | amTimeAgo }}
              </td>
            </tr>
            <tr>
              <td>
                <span>Ryan Kleeberger</span>
                <span class="nickname">ryanklee</span>
              </td>
              <td>Software Developer</td>
              <td>C#, .NET, JavaScript</td>
              <td>
                {{ getExperienceYears(2017) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://github.com/ryanklee" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(11, 5, 2017) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Akshit Bhalla</span>
                <span class="nickname">akshitbhalla</span>
              </td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>
                {{ getJoinedOnDate(19, 10, 2017) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Jose Antonio Soto Villacampa</span>
                <span class="nickname">JoseASotoV</span>
              </td>
              <td>Software Developer</td>
              <td>.NET, C#, MS SQL</td>
              <td>
                {{ getExperienceYears(2014) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://github.com/JoseASotoV" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://mx.linkedin.com/in/joseantoniosoto" target="_blank"><span class="fa fa-linkedin fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(12, 9, 2017) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Stratos Kourtzanidis</span>
                <span class="nickname">melkor54248</span>
              </td>
              <td> Software Engineer</td>
              <td> ASP .NET, Azure, C#, SQL, JavaScript, Microsoft Bot Framework </td>
              <td>
                {{ getExperienceYears(2009) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://github.com/melkor54248" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://twitter.com/ThessNetMeetUp/" target="_blank"><span class="fa fa-twitter fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(25, 08, 2017) | amTimeAgo }}
              </td>
            </tr>
            <tr>
              <td>
                <span>Sarah Sexton</span>
                <span class="nickname">Saelia</span>
              </td>
              <td>Commercial Software Engineer</td>
              <td>Microsoft Bot Framework, Cognitive Services, Azure, HoloLens, Unity</td>
              <td>
                {{ getExperienceYears(2014) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://www.linkedin.com/in/sarahjeannesexton" target="_blank"><span class="fa fa-linkedin fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://github.com/SarahSexton" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://twitter.com/Saelia" target="_blank"><span class="fa fa-twitter fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(25, 07, 2017) | amTimeAgo }}
              </td>
            </tr>
            <tr>
              <td>
                <span>Mandeep Sekhon</span>
                <span class="nickname">m-sekhon</span>
              </td>
              <td>Coder, tester</td>
              <td>C#, .NET, AngularJS, SQL</td>
              <td>
                {{ getExperienceYears(2010) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://www.linkedin.com/in/mandeep-sekhon-83673019" target="_blank"><span class="fa fa-linkedin fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://github.com/m-sekhon" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://twitter.com/mandeep_sekhon" target="_blank"><span class="fa fa-twitter fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(30, 03, 2017) | amTimeAgo }}
              </td>
            </tr>
            <tr>
              <td>
                <span>Derek Poehls</span>
                <span class="nickname">dpoehls</span>
              </td>
              <td>Coder, tester</td>
              <td>Network Design/Implementation, C#, PHP, SQL</td>
              <td>
                {{ getExperienceYears(2005) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://www.linkedin.com/in/dpoehls" target="_blank"><span class="fa fa-linkedin fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(07, 01, 2017) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Carmen Pomana</span>
                <span class="nickname">carmenne</span>
              </td>
              <td>Software Developer</td>
              <td>C#, SQL, ASP.NET MVC, JavaScript</td>
              <td>
                {{ getExperienceYears(2013) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://github.com/carmenne" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(3, 12, 2016) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Shilpa AB</span>
                <span class="nickname">Shilps</span>
              </td>
              <td>Developer and Student</td>
              <td>Python, Django, JS, AngularJS, SQL, D3</td>
              <td>
                {{ getExperienceYears(2010) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://github.com/shilpavijay" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(28, 11, 2016) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Mégane Vilan</span>
                <span class="nickname">Calypso</span>
              </td>
              <td>Software Developer & student</td>
              <td>C#, Javascript, AngularJS, C++</td>
              <td>
                {{ getExperienceYears(2012) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://github.com/megane-vilain" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://fr.linkedin.com/in/mégane-vilain-3b2624a6" target="_blank"><span class="fa fa-linkedin fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://twitter.com/BlackSkilly" target="_blank"><span class="fa fa-twitter fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(20, 11, 2016) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Daniel Gomez</span>
                <span class="nickname">Usagitan</span>
              </td>
              <td>Coder</td>
              <td>C#, .NET</td>
              <td>
                {{ getExperienceYears(2015) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://github.com/Usagitan" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/daniel-gomez-544b736b" target="_blank"><span class="fa fa-linkedin fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(8, 11, 2016) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Emily Cassandra</span>
                <span class="nickname">ecmeeker</span>
              </td>
              <td>Coder</td>
              <td>C#, ASP.NET, SQL, MVC, JavaScript</td>
              <td>
                {{ getExperienceYears(2014) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="http://emilycassandra.com/" target="_blank"><span class="fa fa-globe fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://twitter.com/emicassandram" target="_blank"><span class="fa fa-twitter fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/emily-cassandra-meeker-4662a099" target="_blank"><span class="fa fa-linkedin fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="mailto:emilycassandra@emilycassandra.com" target="_blank"><span class="fa fa-envelope fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(30, 10, 2016) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Carolyn Langton</span>
                <span class="nickname">Lindy</span>
              </td>
              <td>Coder, tester, documentation</td>
              <td>C#, .NET, JavaScript, HTML, CSS, T-SQL</td>
              <td>
                {{ getExperienceYears(2015) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://github.com/carolynlangton" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://twitter.com/carolyntiry" target="_blank"><span class="fa fa-twitter fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/carolyntiry" target="_blank"><span class="fa fa-linkedin fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(29, 10, 2016) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Angel Rodriguez</span>
                <span class="nickname">angelfeliz</span>
              </td>
              <td>Coder</td>
              <td>.NET, javascript, jquery, angularjs, SQL, C#, HTML, CSS</td>
              <td>
                {{ getExperienceYears(2013) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://github.com/angelfeliz" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/erodriguezfeliz" target="_blank"><span class="fa fa-linkedin fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="mailto:erodriguezfeliz@gmail.com" target="_blank"><span class="fa fa-envelope fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(5, 10, 2016) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Todd Cullum</span>
                <span class="nickname">TheOnlyRealTodd</span>
              </td>
              <td>Software Developer/Computer Scientist</td>
              <td>.NET, MS SQL, C++, JavaScript</td>
              <td>
                {{ getExperienceYears(2013) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://github.com/theonlyrealtodd" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://twitter.com/theonlyrealtodd" target="_blank"><span class="fa fa-twitter fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/todd-cullum" target="_blank"><span class="fa fa-linkedin fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://medium.com/@TheOnlyRealTodd" target="_blank"><span class="fa fa-medium fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(03, 10, 2016) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Sandra Dudley</span>
                <span class="nickname">Sandra</span>
              </td>
              <td>Designer, Coder</td>
              <td>HTML, CSS, Javascript</td>
              <td>
                &nbsp;
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://github.com/sandra-dudley" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(01, 10, 2016) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Robert Axelsen</span>
                <span class="nickname">robeerob</span>
              </td>
              <td>Coder</td>
              <td>HTML, CSS, JavaScript, Node.js, jQuery</td>
              <td>
                {{ getExperienceYears(2015) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://github.com/robeerob" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(28, 9, 2016) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Simon Foster</span>
                <span class="nickname">funkysi1701</span>
              </td>
              <td>Software Developer</td>
              <td>C#, .Net, SQL, MVC</td>
              <td>
                {{ getExperienceYears(2014) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="mailto:funkysi1701@gmail.com" target="_blank"><span class="fa fa-envelope fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://github.com/funkysi1701" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://twitter.com/funkysi1701" target="_blank"><span class="fa fa-twitter fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="http://funkysi1701.com" target="_blank"><span class="fa fa-globe fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/funkysi1701" target="_blank"><span class="fa fa-linkedin fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(25, 9, 2016) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Ifeanyi Okadigbo</span>
                <span class="nickname">e.kads</span>
              </td>
              <td>Coder, designer, tester</td>
              <td>Html, css, php, nodeJS, angular</td>
              <td>
                {{ getExperienceYears(2015) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://github.com/edwardkads" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="mailto:okadigbo.ifeanyi@gmail.com" target="_blank"><span class="fa fa-envelope fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(18, 8, 2016) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Caroline Ho</span>
                <span class="nickname">carolineh101</span>
              </td>
              <td>Coder</td>
              <td>HTML, CSS, JavaScript, Android</td>
              <td>
                {{ getExperienceYears(2015) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://github.com/carolineh101" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="mailto:cho19@stanford.edu" target="_blank"><span class="fa fa-envelope fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(29, 7, 2016) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Shai Aharoni</span>
                <span class="nickname">InternetBird</span>
              </td>
              <td>Software Developer</td>
              <td>.NET, Javascript</td>
              <td>
                {{ getExperienceYears(2011) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://twitter.com/internetbird" target="_blank"><span class="fa fa-twitter fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="mailto:shai_aharoni@hotmail.com" target="_blank"><span class="fa fa-envelope fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(9, 7, 2016) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Sean Nam</span>
                <span class="nickname">snam23</span>
              </td>
              <td>Software Developer & CS student</td>
              <td>Java, JSP, servlets, HTML, CSS, Javascript, Oracle SQl, Swift</td>
              <td>
                {{ getExperienceYears(2012) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="http://seannam.com" target="_blank"><span class="fa fa-globe fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://github.com/seannam" target="_blank"><span class="fa fa-github fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/seannam" target="_blank"><span class="fa fa-linkedin fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://twitter.com/nam_sean" target="_blank"><span class="fa fa-twitter fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(29, 6, 2016) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Johnathon Sykes</span>
                <span class="nickname">MrJSykes</span>
              </td>
              <td>Coder</td>
              <td>HTML, CSS, Javascript, Social Media</td>
              <td>
                {{ getExperienceYears(2015) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://twitter.com/seesykescode" target="_blank"><span class="fa fa-twitter fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/johnathondsykes" target="_blank"><span class="fa fa-linkedin fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(28, 6, 2016) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Özgür Sengelli</span>
                <span class="nickname">osengelli</span>
              </td>
              <td>1. Mate</td>
              <td>Graphics support, content management, documentation</td>
              <td>
                {{ getExperienceYears(2006) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://www.behance.net/ozgursengelli" target="_blank"><span class="fa fa-behance fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(24, 6, 2016) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Azize Leylek</span>
                <span class="nickname">ianthine</span>
              </td>
              <td>Coder</td>
              <td>HTML, CSS, javascript</td>
              <td>
                {{ getExperienceYears(2015) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://twitter.com/azizeleylek" target="_blank"><span class="fa fa-twitter fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(19, 6, 2016) | amTimeAgo }}
              </td>
            </tr>

            <tr>
              <td>
                <span>Serkan Holat</span>
                <span class="nickname">coni2k</span>
              </td>
              <td>Software Developer</td>
              <td>ASP.NET, Angular, C#, MS SQL</td>
              <td>
                {{ getExperienceYears(2001) }}
              </td>
              <td>
                <ul class="list-inline">
                  <li>
                    <a href="https://twitter.com/coni2k" target="_blank"><span class="fa fa-twitter fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/serkanholat" target="_blank"><span class="fa fa-linkedin fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="mailto:serkan.holat@forcrowd.org" target="_blank"><span class="fa fa-envelope fa-lg" aria-hidden="true"></span></a>
                  </li>
                  <li>
                    <a href="http://forcrowd.org" target="_blank"><span class="fa fa-globe fa-lg" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </td>
              <td>
                {{ getJoinedOnDate(16, 6, 2016) | amTimeAgo }}
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
