/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../project-editor/project-editor.component.ngfactory";
import * as i2 from "../project-editor/project-editor.component";
import * as i3 from "@forcrowd/backbone-client-core";
import * as i4 from "../app-project.service";
import * as i5 from "@angular/router";
import * as i6 from "../../logger/logger.service";
import * as i7 from "./project-viewer.component";
var styles_ProjectViewerComponent = [];
var RenderType_ProjectViewerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProjectViewerComponent, data: {} });
export { RenderType_ProjectViewerComponent as RenderType_ProjectViewerComponent };
export function View_ProjectViewerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "project-editor", [], null, null, null, i1.View_ProjectEditorComponent_0, i1.RenderType_ProjectEditorComponent)), i0.ɵdid(1, 245760, null, 0, i2.ProjectEditorComponent, [i3.AuthService, i4.AppProjectService, i5.Router, i6.Logger], { config: [0, "config"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.projectEditorConfig; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProjectViewerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ProjectViewerComponent_0, RenderType_ProjectViewerComponent)), i0.ɵdid(1, 49152, null, 0, i7.ProjectViewerComponent, [i5.ActivatedRoute, i5.Router], null, null)], null, null); }
var ProjectViewerComponentNgFactory = i0.ɵccf("ng-component", i7.ProjectViewerComponent, View_ProjectViewerComponent_Host_0, {}, {}, []);
export { ProjectViewerComponentNgFactory as ProjectViewerComponentNgFactory };
