import { Router } from "@angular/router";
import { AccountService } from "./account.service";
import { Logger } from "../logger/logger.module";
var AddPasswordComponent = /** @class */ (function () {
    function AddPasswordComponent(accountService, logger, router) {
        this.accountService = accountService;
        this.logger = logger;
        this.router = router;
        this.model = { Password: "", ConfirmPassword: "" };
    }
    Object.defineProperty(AddPasswordComponent.prototype, "isBusy", {
        get: function () {
            return this.accountService.isBusy;
        },
        enumerable: true,
        configurable: true
    });
    AddPasswordComponent.prototype.addPassword = function () {
        // Todo password match validation?
        var _this = this;
        this.accountService.addPassword(this.model)
            .subscribe(function () {
            _this.logger.logSuccess("Your password has been set!");
            _this.reset();
            _this.router.navigate(["/app/account"]);
        });
    };
    AddPasswordComponent.prototype.cancel = function () {
        this.reset();
        this.router.navigate(["/app/account"]);
    };
    AddPasswordComponent.prototype.canDeactivate = function () {
        if (this.model.Password === ""
            && this.model.ConfirmPassword === "") {
            return true;
        }
        return confirm("Discard changes?");
    };
    AddPasswordComponent.prototype.reset = function () {
        this.model.Password = "";
        this.model.ConfirmPassword = "";
    };
    return AddPasswordComponent;
}());
export { AddPasswordComponent };
