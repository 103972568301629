import { OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService, ElementFieldDataType, ElementItem as CoreElementItem, User } from "@forcrowd/backbone-client-core";
import { timer as observableTimer, Subject } from "rxjs";
import { mergeMap, debounceTime, finalize } from "rxjs/operators";
import { Logger } from "../../logger/logger.module";
import { ChartConfig, ChartDataItem } from "../../ng-chart/ng-chart.module";
import { RatingMode } from "../entities/project";
import { AppProjectService } from "../app-project.service";
var ProjectEditorComponent = /** @class */ (function () {
    function ProjectEditorComponent(authService, projectService, router, logger) {
        var _this = this;
        this.authService = authService;
        this.projectService = projectService;
        this.router = router;
        this.logger = logger;
        this.config = { projectId: 0 };
        this.chartConfig = null;
        this.currentUser = null;
        this.displayChart = false;
        this.displayDescription = false;
        this.displayIndexDetails = false;
        this.elementFieldDataType = ElementFieldDataType;
        this.elementItemsSortField = "allRoundsIncome";
        this.errorMessage = "";
        this.ratingMode = RatingMode;
        this.projectId = 0;
        this.saveStream = new Subject();
        this.subscriptions = [];
        this.username = "";
        // When items income decreased then push to array for each rounds
        this.decreasedItems = [];
        // User Project Array
        this.projectDataSet = [];
        // User project list - selected project Id
        this.loadProjectId = 0;
        // count current items
        this.elementItemCount = 0;
        this.paused = false;
        // income compare set
        this.incomeCompareSet = { "before": {}, "after": {}, "round": {} };
        // Using for star buttons
        this.starValue = 0;
        this.elementItemOfStarButton = null;
        // Timer schedule
        this.timerDelay = 1000;
        this.timerSubscription = observableTimer(1500, this.timerDelay).subscribe(function () {
            _this.refreshPage();
            _this.changeStatus();
        });
        // QRCode
        this.qrCodeData = document.location.href;
        this.fields = {
            selectedElement: null,
        };
    }
    Object.defineProperty(ProjectEditorComponent.prototype, "isBusy", {
        get: function () {
            return this.projectService.isBusy;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProjectEditorComponent.prototype, "selectedElement", {
        get: function () {
            return this.fields.selectedElement;
        },
        set: function (value) {
            if (this.fields.selectedElement !== value) {
                this.fields.selectedElement = value;
                this.loadChartData();
            }
        },
        enumerable: true,
        configurable: true
    });
    // Get project list of current user
    ProjectEditorComponent.prototype.getProjectSet = function () {
        var _this = this;
        this.projectService.getProjectSet(this.currentUser.UserName).pipe(finalize(function () {
            //TODO: Do something ?
            // Delete after ?
            _this.projectDataSet.forEach(function (p, i) {
                console.log("Project Name:", p.Name, p.Id);
            });
        }))
            .subscribe(function (results) {
            _this.projectDataSet = results;
        });
    };
    ProjectEditorComponent.prototype.changeProject = function () {
        this.router.navigate(["/project", this.loadProjectId]);
    };
    // Pause-play Timer
    ProjectEditorComponent.prototype.startStop = function () {
        this.paused = !this.paused;
    };
    /**
    * Reset Timer
    * - Timer delay set 1 seconds (by defaul)
    */
    ProjectEditorComponent.prototype.resetTimer = function () {
        var _this = this;
        this.project.resetRounds();
        this.decreasedItems = []; //clear
        this.timerDelay = 1000;
        this.timerSubscription.unsubscribe();
        this.timerSubscription = observableTimer(1000, this.timerDelay).subscribe(function () {
            _this.refreshPage();
            _this.changeStatus();
        });
        console.log("Reset: Timer delay time set to 1 second..");
    };
    // Increase: Timer delay duration has been doubled
    ProjectEditorComponent.prototype.decreaseSpeed = function () {
        var _this = this;
        if (this.timerDelay * 2 > 4000)
            return;
        this.timerDelay *= 2;
        this.timerSubscription.unsubscribe();
        this.timerSubscription = observableTimer(1000, this.timerDelay).subscribe(function () {
            _this.refreshPage();
            _this.changeStatus();
        });
        console.log("Timer delay time set to " + this.timerDelay / 1000 + " s.");
    };
    // Decrease: Timer delay duration has been halved
    ProjectEditorComponent.prototype.increaseSpeed = function () {
        var _this = this;
        if (this.timerDelay / 2 < 250)
            return;
        this.timerDelay /= 2;
        this.timerSubscription.unsubscribe();
        this.timerSubscription = observableTimer(1000, this.timerDelay).subscribe(function () {
            _this.refreshPage();
            _this.changeStatus();
        });
        console.log("Timer delay time set to " + this.timerDelay / 1000 + " s.");
    };
    ProjectEditorComponent.prototype.incomeCompareSetInit = function () {
        var _this = this;
        this.project.ElementSet.forEach(function (element) {
            element.ElementItemSet.forEach(function (elementItem) {
                if (_this.incomeCompareSet["before"][elementItem.Id] === undefined) {
                    _this.incomeCompareSet["before"][elementItem.Id] = { "sort": 0, "income": 0 };
                    _this.incomeCompareSet["after"][elementItem.Id] = { "sort": 0, "income": 0 };
                    _this.incomeCompareSet["before"][elementItem.Id]["income"] = elementItem.income();
                    _this.incomeCompareSet["after"][elementItem.Id]["income"] = elementItem.income();
                    _this.incomeCompareSet["before"][elementItem.Id]["sort"] = 0;
                    _this.incomeCompareSet["after"][elementItem.Id]["sort"] = 0;
                }
            });
        });
        this.project.ElementSet[0].getElementItemSet(this.elementItemsSortField).forEach(function (item, sortOrder) {
            _this.incomeCompareSet["before"][item.Id]["sort"] = sortOrder;
            _this.incomeCompareSet["after"][item.Id]["sort"] = sortOrder;
        });
    };
    // Timer refresh income
    ProjectEditorComponent.prototype.refreshPage = function () {
        var _this = this;
        if (this.paused) {
            return;
        }
        this.project.increaseRounds();
        /* income compare */
        this.incomeCompareSetInit();
        var mainElement = this.project.ElementSet[0];
        this.project.ElementSet.forEach(function (element) {
            element.ElementItemSet.forEach(function (elementItem) {
                var before = _this.incomeCompareSet["before"][elementItem.Id]["income"];
                var after = _this.incomeCompareSet["after"][elementItem.Id]["income"];
                // If elementItem income is change then ?
                if (after !== elementItem.income()) {
                    _this.incomeCompareSet["before"][elementItem.Id]["income"] = _this.incomeCompareSet["after"][elementItem.Id]["income"];
                    _this.incomeCompareSet["after"][elementItem.Id]["income"] = elementItem.income();
                    _this.incomeCompareSet["round"][elementItem.Id] = 0;
                }
                if (before < after) { // increased
                    _this.incomeCompareSet["round"][elementItem.Id] = _this.incomeCompareSet["round"][elementItem.Id] + 1;
                }
                else if (before > after) { // decreased
                    _this.incomeCompareSet["round"][elementItem.Id] = _this.incomeCompareSet["round"][elementItem.Id] + 1;
                }
                else { // average
                    _this.incomeCompareSet["round"][elementItem.Id] = 0;
                }
                _this.project.ElementSet[0].getElementItemSet(_this.elementItemsSortField).forEach(function (item, sortOrder) {
                    if (_this.incomeCompareSet["before"][item.Id]["sort"] !== sortOrder) {
                        _this.incomeCompareSet["before"][item.Id]["sort"] = _this.incomeCompareSet["after"][item.Id]["sort"];
                        _this.incomeCompareSet["after"][item.Id]["sort"] = sortOrder;
                        _this.incomeCompareSet["round"][item.Id] = 0;
                        console.log(item.Name + "'s sort order has been changed!");
                    }
                });
                // Sort for allRoundsIncome (only for the main)
                if (_this.selectedElement === mainElement)
                    _this.selectedElement.getElementItemSet("allRoundsIncome");
            });
        });
    };
    // Change selected item if parent item is decreasing
    ProjectEditorComponent.prototype.changeStatus = function () {
        var _this = this;
        if (this.paused) {
            return;
        }
        this.project.ElementSet.forEach(function (element) {
            element.ElementItemSet.forEach(function (elementItem) {
                var before = _this.incomeCompareSet["before"][elementItem.Id]["income"];
                var after = _this.incomeCompareSet["after"][elementItem.Id]["income"];
                if (before < after) { // increased
                    // Increased income item removes array from decreasedItems
                    if (element === _this.project.ElementSet[0]) {
                        if (_this.decreasedItems.indexOf(elementItem) > -1)
                            _this.decreasedItems.splice(_this.decreasedItems.indexOf(elementItem), 1);
                    }
                }
                else if (before > after) { // decrease
                    // Item decreases when i try to change item selectedItemElement (only for the main)
                    if (element === _this.project.ElementSet[0]) {
                        if (_this.decreasedItems.indexOf(elementItem) === -1)
                            _this.decreasedItems.push(elementItem);
                    }
                }
            });
        });
        // Check for transformation, only if there are more than one element (there must be a child element)
        // TODO Even this is not a good check / coni2k - 1 Nov. '18
        if (this.project.ElementSet.length > 1) {
            // When item income decreases tried to change the status
            if (this.decreasedItems.length > 0)
                this.chanceSelectedElementItem();
        }
    };
    // Change parent element selected item
    // If random number is greater then seven.
    ProjectEditorComponent.prototype.chanceSelectedElementItem = function () {
        var r = Math.floor(Math.random() * this.decreasedItems.length);
        var random = Math.floor(Math.random() * 10);
        //this.decreasedItems.forEach((x, i) => console.log(i, x.Name));
        if (random > 7) {
            var item = this.decreasedItems[r];
            var elementSet = this.project.ElementSet;
            var elementCellSet = item.ElementCellSet;
            console.log(" -- Parent Element:", item.Name);
            if (elementSet.length > 0) {
                if (elementCellSet.length > 0) {
                    for (var i = 0; i < elementCellSet.length; i++) {
                        var selectedItem = item.ElementCellSet[i].SelectedElementItem;
                        console.log("Selected Item: ", selectedItem.Name);
                        item.ElementCellSet[i].SelectedElementItem = this.randomItem(selectedItem.Element.ElementItemSet, selectedItem);
                        console.log("new Selected Item: ", item.ElementCellSet[i].SelectedElementItem.Name);
                    }
                    // Item removes array from decreasedItems
                    this.decreasedItems.splice(this.decreasedItems.indexOf(item), 1);
                }
            }
            //console.log("Length:", this.decreasedItems.length);
        }
    };
    // Generate random item form ElementItem array but exclude current selectedElementItem!
    ProjectEditorComponent.prototype.randomItem = function (elementItemSet, item) {
        var rand = Math.floor(Math.random() * elementItemSet.length);
        if (elementItemSet[rand] === item) {
            return this.randomItem(elementItemSet, item);
        }
        else {
            return elementItemSet[rand];
        }
    };
    ProjectEditorComponent.prototype.getIncomeCompareStatus = function (elementItem) {
        if (this.project.rounds === 0)
            this.incomeCompareSetInit();
        // for new item
        if (this.incomeCompareSet["before"][elementItem] === undefined) {
            this.incomeCompareSet["before"][elementItem] = { "sort": 0, "income": 0 };
            this.incomeCompareSet["after"][elementItem] = { "sort": 0, "income": 0 };
            this.incomeCompareSet["before"][elementItem]["income"] = 0;
            this.incomeCompareSet["after"][elementItem]["income"] = 0;
            this.incomeCompareSet["before"][elementItem]["sort"] = 0;
            this.incomeCompareSet["after"][elementItem]["sort"] = 0;
        }
        var before = this.incomeCompareSet["before"][elementItem]["income"];
        var after = this.incomeCompareSet["after"][elementItem]["income"];
        var round = this.incomeCompareSet["round"][elementItem];
        // Arrows only remain five times!
        if (before < after) {
            return round < 5 ? "fa fa-caret-up pull-right text-success" : "fa fa-arrows-h pull-right text-dark";
        }
        else if (before > after) {
            return round < 5 ? "fa fa-caret-down pull-right text-danger" : "fa fa-arrows-h pull-right text-dark";
        }
        else {
            return "fa fa-arrows-h pull-right text-dark";
        }
    };
    /* for Rating buttons */
    ProjectEditorComponent.prototype.isStarFull = function (value, compare, elementItem) {
        if (elementItem === this.elementItemOfStarButton) {
            return value >= compare ? this.starValue * 25 <= compare ? false : true : false;
        }
        else {
            return value >= compare ? true : false;
        }
    };
    ProjectEditorComponent.prototype.navigationOverStars = function (value, elementItem) {
        return this.starValue >= value && this.elementItemOfStarButton === elementItem ? true : false;
    };
    ProjectEditorComponent.prototype.mouseEnter = function (value, elementItem) {
        this.starValue = value;
        this.elementItemOfStarButton = elementItem;
    };
    ProjectEditorComponent.prototype.mouseLeave = function (value, elementItem) {
        this.starValue = value;
        this.elementItemOfStarButton = elementItem;
    };
    ProjectEditorComponent.prototype.initialize = function (projectId, user) {
        var _this = this;
        // If there is no change, no need to continue
        if (this.projectId === projectId && this.currentUser === user) {
            return;
        }
        this.projectId = projectId;
        this.currentUser = user;
        // Clear previous error messages
        this.errorMessage = "";
        // Validate
        if (!this.projectId) {
            this.errorMessage = "Project id cannot be null";
            return;
        }
        // Get project
        this.projectService.getProjectExpanded(this.config.projectId)
            .subscribe(function (project) {
            if (!project) {
                _this.errorMessage = "Invalid project";
                return;
            }
            _this.project = project;
            // Set Initial value + setIncome()
            _this.project.initialValue = _this.config.initialValue || 100;
            _this.project.ElementSet.forEach(function (element) {
                element.ElementFieldSet.forEach(function (field) {
                    field.setIncome();
                });
            });
            // Rating mode updated event
            // TODO: Unsubscribe?
            _this.project.ratingModeUpdated.subscribe(function () { return _this.updateElementItemsSortField(); });
            // Selected element
            _this.selectedElement = _this.project.ElementSet[0];
            _this.loadChartData();
        });
    };
    ProjectEditorComponent.prototype.loadChartData = function () {
        var element = this.selectedElement;
        if (!element) {
            return;
        }
        // Item length check
        if (element.ElementItemSet.length > 20) {
            return;
        }
        if (!this.displayIndexDetails) {
            // TODO Check this rule?
            if (element === element.familyTree()[0] && element.income() > 0) {
                var options = {
                    title: { text: element.Name },
                    chart: { type: "column" },
                    yAxis: {
                        title: { text: "Total Income" }
                    }
                };
                var data_1 = [];
                element.ElementItemSet.forEach(function (elementItem) {
                    data_1.push(new ChartDataItem(elementItem.Name, elementItem.allRoundsIncome(), elementItem.allRoundsIncomeUpdated));
                });
                this.chartConfig = new ChartConfig(options, data_1);
            }
            else {
                var options = {
                    title: { text: element.Name },
                    chart: { type: "pie" }
                };
                var data_2 = [];
                element.ElementItemSet.forEach(function (elementItem) {
                    elementItem.ElementCellSet.forEach(function (elementCell) {
                        if (elementCell.ElementField.RatingEnabled) {
                            data_2.push(new ChartDataItem(elementCell.ElementItem.Name, +elementCell.decimalValue().toFixed(2), elementCell.decimalValueUpdated));
                        }
                    });
                });
                this.chartConfig = new ChartConfig(options, data_2);
            }
        }
        else {
            var options = {
                title: { text: "Indexes" },
                chart: { type: "pie" }
            };
            var data_3 = [];
            element.elementFieldSet()
                .forEach(function (field) {
                data_3.push(new ChartDataItem(field.Name, +field.rating().toFixed(2), field.ratingUpdated));
            });
            this.chartConfig = new ChartConfig(options, data_3);
        }
    };
    // if parent element cells selected item then true
    ProjectEditorComponent.prototype.isSelectedElementItem = function (elementItem, elementCell) {
        var isSelectedElementItem = false;
        var mainElement = this.project.ElementSet[0]; // Main (Parent) Element
        if (!this.isBusy) {
            mainElement.ElementItemSet.forEach(function (item) {
                if (item.ElementCellSet.length > 0) {
                    item.ElementCellSet.forEach(function (cell) {
                        if (cell.ElementField.DataType === 6) {
                            if (cell.SelectedElementItem.Id === elementItem.Id)
                                isSelectedElementItem = true;
                        }
                    });
                }
                else {
                    isSelectedElementItem = true;
                }
            });
        }
        // This role is only for parent element, other elements return as isSelectedElementItem
        var result = this.selectedElement === mainElement ? true : isSelectedElementItem;
        //TODO: check! - if result is false (item rating buttons are disabled) maybe rating set zero
        //if (!result && elementCell !== null) { this.updateElementCellDecimalValue(elementCell, 0)
        return result;
    };
    // Remove element item
    ProjectEditorComponent.prototype.removeElementItem = function (elementItem) {
        // is this a selected item of parent element?
        if (!this.isSelectedElementItem(elementItem)) {
            this.projectService.removeElementItem(elementItem);
            this.saveStream.next();
        }
        else {
            this.logger.logError("This item is parent element selected item, should not be remove");
        }
    };
    // Add a new item
    ProjectEditorComponent.prototype.addElementItem = function () {
        var _this = this;
        this.elementItemCount = this.elementItemCount === 0
            ? this.selectedElement.ElementItemSet.length
            : this.elementItemCount + 1;
        // New element Item
        var newElementItem = this.projectService.createElementItem({
            Element: this.selectedElement,
            Name: "Item " + (this.elementItemCount + 1)
        });
        // Cells
        this.selectedElement.ElementFieldSet.forEach(function (field) {
            var elementCell = _this.projectService.createElementCell({
                ElementField: field,
                ElementItem: newElementItem
            });
            // If DataType is decimal, also create "User element cell"
            if (field.DataType === ElementFieldDataType.Decimal) {
                elementCell.DecimalValueTotal = 0; // Computed field
                elementCell.DecimalValueCount = 1; // Computed field
                _this.projectService.createUserElementCell(elementCell, 0);
            }
            switch (field.DataType) {
                case ElementFieldDataType.String: {
                    break;
                }
                case ElementFieldDataType.Decimal: {
                    break;
                }
                case ElementFieldDataType.Element:
                    {
                        var randomItemIndex = Math.floor(Math.random() * field.SelectedElement.ElementItemSet.length);
                        elementCell.SelectedElementItem = field.SelectedElement.ElementItemSet[randomItemIndex];
                        break;
                    }
            }
        });
        // Set Income
        this.project.ElementSet.forEach(function (element) {
            element.ElementFieldSet.forEach(function (field, i) {
                field.setIncome();
            });
        });
        this.saveStream.next();
        this.loadChartData();
    };
    ProjectEditorComponent.prototype.addNewElementField = function () {
        var _this = this;
        var mainElement = this.project.ElementSet[0];
        this.elementItemCount === 0
            ? this.elementItemCount = this.selectedElement.ElementItemSet.length
            : this.elementItemCount += 1;
        // New Element
        var newElement = this.projectService.createElement({
            Project: this.project,
            Name: "Element " + (this.project.ElementSet.length + 1)
        });
        // Element Fields
        var newElementField = this.projectService.createElementField({
            Element: this.selectedElement,
            Name: "New Field",
            DataType: ElementFieldDataType.Decimal,
            UseFixedValue: false,
            RatingEnabled: true,
            SortOrder: newElement.ElementFieldSet.length + 1,
            RatingTotal: 0,
            RatingCount: 1 // Computed field
        });
        this.projectService.createUserElementField(newElementField, 50);
        // Element Items
        var newElementItem1 = this.projectService.createElementItem({
            Element: newElement,
            Name: "Element " + this.project.ElementSet.length + " item 1"
        });
        var newElementItem2 = this.projectService.createElementItem({
            Element: newElement,
            Name: "Element " + this.project.ElementSet.length + " item 2"
        });
        // Element cell 1
        var newCell1 = this.projectService.createElementCell({
            ElementField: newElementField,
            ElementItem: newElementItem1,
            DecimalValueTotal: 0,
            DecimalValueCount: 1
        });
        // User element cell 1
        this.projectService.createUserElementCell(newCell1, 0);
        // Element cell 2
        var newCell2 = this.projectService.createElementCell({
            ElementField: newElementField,
            ElementItem: newElementItem2,
            DecimalValueTotal: 0,
            DecimalValueCount: 1
        });
        // User element cell 2
        this.projectService.createUserElementCell(newCell2, 0);
        /* --- */
        // Main Element New Field
        var newField = this.projectService.createElementField({
            Element: mainElement,
            Name: newElement.Name,
            DataType: ElementFieldDataType.Element,
            SortOrder: mainElement.ElementFieldSet.length + 1
        });
        newField.SelectedElement = newElement;
        // Cells
        newField.Element.ElementItemSet.forEach(function (elementItem, i) {
            _this.projectService.createElementCell({
                ElementField: newField,
                ElementItem: elementItem
            });
        });
        //console.log("efs, eis", mainElement.ElementFieldSet.length, mainElement.ElementItemSet.length);
        mainElement.ElementItemSet[0].ElementCellSet[mainElement.ElementFieldSet.length - 1].SelectedElementItem =
            newElementItem1;
        mainElement.ElementItemSet[1].ElementCellSet[mainElement.ElementFieldSet.length - 1].SelectedElementItem =
            newElementItem2;
        // if the item is added before?
        if (mainElement.ElementItemSet.length > 2) {
            for (var i = 2; i < mainElement.ElementItemSet.length; i++) {
                var randomNewElementItem = newElement.ElementItemSet[Math.floor(Math.random() * newElement.ElementItemSet.length)];
                mainElement.ElementItemSet[i].ElementCellSet[mainElement.ElementFieldSet.length - 1].SelectedElementItem = randomNewElementItem;
            }
        }
        this.saveStream.next();
        this.project.ElementSet.forEach(function (element) {
            element.ElementFieldSet.forEach(function (field, i) {
                field.Element.setFamilyTree();
                field.setIncome();
                field.ElementCellSet.forEach(function (cell, c) {
                });
            });
        });
        this.incomeCompareSetInit();
    };
    ProjectEditorComponent.prototype.ngOnDestroy = function () {
        this.timerSubscription.unsubscribe();
        for (var i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    };
    ProjectEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        // projectId
        var projectId = this.config.projectId || 0;
        // Delayed save operation
        this.saveStream.pipe(debounceTime(1500), mergeMap(function () { return _this.projectService.saveChanges(); })).subscribe();
        // User changed event handler
        this.subscriptions.push(this.authService.currentUserChanged.subscribe(function (newUser) {
            return _this.initialize(_this.projectId, newUser);
        }));
        this.initialize(projectId, this.authService.currentUser);
        // Fetch data: Get project list of current user
        //this.getProjectSet();
    };
    ProjectEditorComponent.prototype.toggleDescription = function () {
        this.displayDescription = !this.displayDescription;
    };
    // Index Details
    ProjectEditorComponent.prototype.toggleIndexDetails = function () {
        this.displayIndexDetails = !this.displayIndexDetails;
        this.loadChartData();
    };
    ProjectEditorComponent.prototype.updateElementCellDecimalValue = function (cell, value) {
        this.projectService.updateElementCellDecimalValue(cell, value);
        this.saveStream.next();
    };
    ProjectEditorComponent.prototype.updateElementFieldRating = function (elementField, rating) {
        this.projectService.updateElementFieldRating(elementField, rating);
        this.saveStream.next();
    };
    ProjectEditorComponent.prototype.updateElementItemsSortField = function () {
        this.elementItemsSortField = this.project.RatingMode === RatingMode.CurrentUser
            ? "name"
            : "income";
    };
    return ProjectEditorComponent;
}());
export { ProjectEditorComponent };
