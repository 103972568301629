/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./confirm-email.component";
import * as i2 from "@angular/router";
import * as i3 from "./account.service";
import * as i4 from "../logger/logger.service";
var styles_ConfirmEmailComponent = [];
var RenderType_ConfirmEmailComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmEmailComponent, data: {} });
export { RenderType_ConfirmEmailComponent as RenderType_ConfirmEmailComponent };
export function View_ConfirmEmailComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Confirm Email"])), (_l()(), i0.ɵeld(2, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" A confirmation email has been sent to your email address."])), (_l()(), i0.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Please use the link in the email to confirm your email address.\n"])), (_l()(), i0.ɵeld(6, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" If you didn't receive a confirmation email, please send it again."])), (_l()(), i0.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resendConfirmationEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Resend Confirmation Email "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isBusy; _ck(_v, 11, 0, currVal_0); }); }
export function View_ConfirmEmailComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "confirm-email", [], null, null, null, View_ConfirmEmailComponent_0, RenderType_ConfirmEmailComponent)), i0.ɵdid(1, 114688, null, 0, i1.ConfirmEmailComponent, [i2.ActivatedRoute, i3.AccountService, i4.Logger, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmEmailComponentNgFactory = i0.ɵccf("confirm-email", i1.ConfirmEmailComponent, View_ConfirmEmailComponent_Host_0, {}, {}, []);
export { ConfirmEmailComponentNgFactory as ConfirmEmailComponentNgFactory };
