<div class="project-viewer">
  <div class="panel panel-default">
    <div class="heading panel-heading">
      <div class="row">
        <div class="col-md-12">
          <p [hidden]="project || errorMessage.length > 0">
            <span class="label label-info">Loading...</span>
          </p>
          <p [hidden]="errorMessage.length === 0">
            <span class="label label-danger">{{ errorMessage }}</span>
          </p>
        </div>
        <div class="col-md-12" *ngIf="project">
          <div class="clearfix">
            <!-- QRCode -->
            <qrcode class="pull-left mr-1" [qrdata]="qrCodeData" [size]="64" [level]="'M'"></qrcode>
            <!-- Project Name -->
            <p class="pull-left">
              <span class="title panel-title">
                {{ project.Name }}
              </span>
              <br />
              <small>Last modified on {{ project.ModifiedOn | date: 'medium' }}</small>
            </p>
            <div class="btn-group pull-right" role="group" aria-label="Timer Options">
              <button title="Reset Timer" (click)="resetTimer()" class="btn btn-md btn-default ml-2">
                <i class="fa fa-refresh"></i>
              </button>
              <button [disabled]="paused || timerDelay === 4000" class="btn btn-md btn-default ml-2" (click)="decreaseSpeed()">
                <i class="fa fa-backward"></i>
              </button>
              <button title="Timer {{ paused ? 'Start' : 'Paused' }}" class="btn btn-md btn-default ml-2" [ngClass]="{'btn-warning': paused, 'btn-primary': !paused}" (click)="startStop()">
                <i [attr.class]="paused ? 'fa fa-play' : 'fa fa-pause'"></i>
              </button>
              <button [disabled]="paused || timerDelay === 250" class="btn btn-md btn-default ml-2" (click)="increaseSpeed()">
                <i class="fa fa-forward"></i>
              </button>
              <button class="btn btn-md btn-default">
                <i class="fa fa-clock-o"></i>
                {{ timerDelay / 1000 }}s.
              </button>
            </div>
          </div>
          <hr />
          <div class="settings">
            <div class="btn-group" role="group" aria-label="Rating Options">
              <button (click)="project.toggleRatingMode()" type="button" class="btn btn-default" [ngClass]="{ 'active': project.RatingMode === ratingMode.CurrentUser }">
                Your Ratings
              </button>
              <button (click)="project.toggleRatingMode()" type="button" class="btn btn-default" [ngClass]="{ 'active': project.RatingMode === ratingMode.AllUsers }">
                All Users
                <span class="g-ml-10 badge">{{ project.RatingCount }}</span>
              </button>
              <button *ngIf="project.User.Id === currentUser.Id" (click)="addNewElementField()" class="btn btn-md btn-default ml-2">
                Add field
              </button>
              <button *ngIf="project.User.Id === currentUser.Id" (click)="addElementItem()" class="btn btn-md btn-default ml-2">
                Add item
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="project">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-12">
            <ol class="breadcrumb" *ngIf="selectedElement.familyTree().length > 0">
              <li *ngFor="let element of selectedElement.familyTree(); let lastItem = last" [ngClass]="{ active: lastItem }">
                <button *ngIf="!lastItem" (click)="selectedElement = element" type="button" class="btn-link" title="Switch to {{ element.Name }} view">
                  {{ element.Name }}
                </button>
                <span *ngIf="lastItem">
                  {{ element.Name }}
                </span>
              </li>
            </ol>
          </div>
          <div *ngIf="selectedElement.ElementItemSet.length <= 20" class="col-md-12">
            <ngChart [config]="chartConfig"></ngChart>
          </div>
        </div>
        <div class="row">
          <div class="details col-md-12">
            <div *ngIf="selectedElement.elementFieldSet().length > 0">
              <label>Total Amount / Rounds</label>
              <span class="text-right">{{ project.initialValue | symbolic }} - {{ project.rounds }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="project-table table table-striped">
                <thead>
                  <tr>
                    <!-- Name -->
                    <th>
                      {{ selectedElement.Name }}
                    </th>
                    <!-- Fields -->
                    <th *ngFor="let field of selectedElement.getElementFieldSetSorted()" [ngClass]="{ 'text-right': field.DataType === elementFieldDataType.Decimal }">
                      <div *ngIf="field.DataType !== elementFieldDataType.Element">
                        {{ field.Name }}<span *ngIf="field.RatingEnabled" title="Index" class="infoText">(I)</span>
                      </div>
                      <span *ngIf="field.DataType === elementFieldDataType.Element">
                        <button (click)="selectedElement = field.SelectedElement" type="button" class="btn-link" title="Switch to {{ field.Name }} view">{{ field.Name }}</button>
                      </span>
                    </th>
                    <!-- Total Income & Details -->
                    <ng-template [ngIf]="displayIndexDetails">
                      <th *ngFor="let field of selectedElement.elementFieldSet()" class="text-right">
                        {{ field.Name + ' (I) Income' }}
                      </th>
                    </ng-template>
                    <th *ngIf="project.initialValue > 0" class="text-right">
                      <!-- Display 'Total Income' view link only if there are more than one index -->
                      <button *ngIf="selectedElement.elementFieldSet().length > 1" (click)="toggleIndexDetails()" type="button" class="btn-link" title="Toggle between 'Indexes' & 'Elements' views">Total Income</button>
                      <!-- If there is only one index, then use that index's name -->
                      <span *ngIf="selectedElement.elementFieldSet().length === 1">Income</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let elementItem of selectedElement.getElementItemSet(elementItemsSortField)">
                    <!-- Name -->
                    <td>
                      <button *ngIf="project.User.Id === currentUser.Id" (click)="removeElementItem(elementItem)" type="button" class="close item-rm" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      {{ elementItem.Name }}
                    </td>
                    <!-- Cells -->
                    <td *ngFor="let elementCell of elementItem.getElementCellSetSorted()" [ngClass]="{ 'text-right': elementCell.ElementField.DataType === elementFieldDataType.Decimal }">
                      <span *ngIf="elementCell.ElementField.DataType === elementFieldDataType.String">{{ elementCell.StringValue }}</span>
                      <div *ngIf="elementCell.ElementField.DataType === elementFieldDataType.Decimal">
                        <div *ngIf="elementCell.ElementField.UseFixedValue">
                          {{ elementCell.decimalValue() | symbolic }}
                        </div>
                        <div *ngIf="!elementCell.ElementField.UseFixedValue">
                          <div *ngIf="elementCell.ElementField.RatingEnabled">
                            <div [hidden]="project.RatingMode === ratingMode.CurrentUser">
                              {{ elementCell.decimalValuePercentage() | percent }}
                            </div>
                            <div [hidden]="project.RatingMode === ratingMode.AllUsers">

                              <!--<span title="selected value">s: {{ elementCell.selectedDecimalValue }}</span> --->
                              <!-- <span title="decimal value" class="g-mr-5">d: {{ elementCell.decimalValue() | number : '1.2' }}</span> -->

                              <div class="btn-group" role="group">
                                <button type="button" class="btn btn-link" (click)="updateElementCellDecimalValue(elementCell, 0)" [disabled]="isBusy || !isSelectedElementItem(elementItem)">
                                  <i class="star fa"
                                    (mouseenter)="mouseEnter(1, elementItem)"
                                    (mouseleave)="mouseLeave(elementCell.decimalValue() / 25 + 1, elementItem)"
                                    [ngClass]="{ 'fa-star': isStarFull(elementCell.decimalValue(), 0) || navigationOverStars(1, elementItem), 'fa-star-o': !isStarFull(elementCell.decimalValue(), 0, elementItem) && !navigationOverStars(1, elementItem) }">
                                  </i>
                                </button>
                                <button type="button" class="btn btn-link" (click)="updateElementCellDecimalValue(elementCell, 25)" [disabled]="isBusy || !isSelectedElementItem(elementItem)">
                                  <i class="star fa"
                                    (mouseenter)="mouseEnter(2, elementItem)"
                                    (mouseleave)="mouseLeave(elementCell.decimalValue() / 25 + 1, elementItem)"
                                    [ngClass]="{ 'fa-star': isStarFull(elementCell.decimalValue(), 25) || navigationOverStars(2, elementItem), 'fa-star-o': !isStarFull(elementCell.decimalValue(), 25, elementItem) && !navigationOverStars(2, elementItem) }">
                                  </i>
                                </button>
                                <button type="button" class="btn btn-link" (click)="updateElementCellDecimalValue(elementCell, 50)" [disabled]="isBusy || !isSelectedElementItem(elementItem)">
                                  <i class="star fa"
                                    (mouseenter)="mouseEnter(3, elementItem)"
                                    (mouseleave)="mouseLeave(elementCell.decimalValue() / 25 + 1, elementItem)"
                                    [ngClass]="{ 'fa-star': isStarFull(elementCell.decimalValue(), 50) || navigationOverStars(3, elementItem), 'fa-star-o': !isStarFull(elementCell.decimalValue(), 50, elementItem) && !navigationOverStars(3, elementItem) }">
                                  </i>
                                </button>
                                <button type="button" class="btn btn-link" (click)="updateElementCellDecimalValue(elementCell, 75)" [disabled]="isBusy || !isSelectedElementItem(elementItem)">
                                  <i class="star fa"
                                    (mouseenter)="mouseEnter(4, elementItem)"
                                    (mouseleave)="mouseLeave(elementCell.decimalValue() / 25 + 1, elementItem)"
                                    [ngClass]="{ 'fa-star': isStarFull(elementCell.decimalValue(), 75) || navigationOverStars(4, elementItem), 'fa-star-o': !isStarFull(elementCell.decimalValue(), 75, elementItem) && !navigationOverStars(4, elementItem) }">
                                  </i>
                                </button>
                                <button type="button" class="btn btn-link" (click)="updateElementCellDecimalValue(elementCell, 100)" [disabled]="isBusy || !isSelectedElementItem(elementItem)">
                                  <i class="star fa"
                                    (mouseenter)="mouseEnter(5, elementItem)"
                                    (mouseleave)="mouseLeave(elementCell.decimalValue() / 25 + 1, elementItem)"
                                    [ngClass]="{ 'fa-star': isStarFull(elementCell.decimalValue(), 100) || navigationOverStars(5, elementItem), 'fa-star-o': !isStarFull(elementCell.decimalValue(), 100, elementItem) && !navigationOverStars(5, elementItem) }">
                                  </i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="!elementCell.ElementField.RatingEnabled">
                            {{ elementCell.decimalValue() | symbolic }}
                          </div>
                        </div>
                      </div>
                      <div *ngIf="elementCell.ElementField.DataType === elementFieldDataType.Element">
                        {{ elementCell.SelectedElementItem !== null ? elementCell.SelectedElementItem.Name : "N/A" }}
                      </div>
                    </td>
                    <!-- Total Income & Details -->
                    <ng-template [ngIf]="displayIndexDetails">
                      <td *ngFor="let ratingCell of elementItem.ratingCells()" class="text-right">
                        {{ ratingCell.allRoundsIncome() | symbolic }}
                      </td>
                    </ng-template>
                    <td *ngIf="project.initialValue > 0" [ngClass]="{ 'incomeStatusLow': elementItem.incomeStatus() === 'low', 'incomeStatusHigh': elementItem.incomeStatus() === 'high' }" class="text-right">
                      <i [attr.class]="getIncomeCompareStatus(elementItem.Id)" style="padding-top: 6px"></i>
                      {{ elementItem.allRoundsIncome() | symbolic }}
                      <!-- i:[{{ elementItem.income().toFixed(2) | symbolic }}] -->
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr *ngIf="selectedElement.ElementItemSet.length > 1">
                    <!-- Name -->
                    <td>
                      &nbsp;
                    </td>
                    <!-- Fields -->
                    <td *ngFor="let field of selectedElement.getElementFieldSetSorted()" [ngClass]="{ 'text-right': field.DataType === elementFieldDataType.Decimal }">
                      &nbsp;
                    </td>
                    <!-- Total Income & Details -->
                    <ng-template [ngIf]="displayIndexDetails">
                      <td *ngFor="let field of selectedElement.elementFieldSet()" class="text-right">
                        {{ field.income() | symbolic }}
                      </td>
                    </ng-template>
                    <td *ngIf="project.initialValue > 0" class="text-right">
                      {{ (project.initialValue * project.rounds) | symbolic }} <!-- Same as income -->
                    </td>
                  </tr>
                  <!-- Functions -->
                  <tr>
                    <!-- Name -->
                    <td class="noborder">

                    </td>
                    <!-- Fields -->
                    <td class="noborder" *ngFor="let field of selectedElement.getElementFieldSetSorted()" [ngClass]="{ 'text-right': field.DataType === elementFieldDataType.Decimal }">
                      &nbsp;
                    </td>
                    <!-- Total Income & Details -->
                    <ng-template [ngIf]="displayIndexDetails">
                      <td *ngFor="let field of selectedElement.elementFieldSet()" class="noborder text-right">
                        <div class="btn-group" role="group">
                          <button type="button" class="btn btn-default btn-xs" (click)="updateElementFieldRating(field, 0)" [disabled]="isBusy">0</button>
                          <button type="button" class="btn btn-default btn-xs" (click)="updateElementFieldRating(field, 25)" [disabled]="isBusy">1</button>
                          <button type="button" class="btn btn-default btn-xs" (click)="updateElementFieldRating(field, 50)" [disabled]="isBusy">2</button>
                          <button type="button" class="btn btn-default btn-xs" (click)="updateElementFieldRating(field, 75)" [disabled]="isBusy">3</button>
                          <button type="button" class="btn btn-default btn-xs" (click)="updateElementFieldRating(field, 100)" [disabled]="isBusy">4</button>
                        </div>
                      </td>
                    </ng-template>
                    <td *ngIf="project.initialValue > 0" class="noborder text-right">
                      &nbsp;
                    </td>
                  </tr>
                  <!-- Project load - hidden for the moment -->
                  <tr style="display: none;">
                    <td class="noborder" colspan="4">
                      <select [(ngModel)]="loadProjectId" class="form-control form-control-lg pull-left">
                        <option *ngFor="let p of projectDataSet; let i=index" [value]="p.Id" [selected]="project.Id === p.Id ? 'selected': null">{{ p.Name }}</option>
                      </select>
                      <button *ngIf="project.User.Id === currentUser.Id" [disabled]="loadProjectId === 0" (click)="changeProject()" class="btn btn-md btn-default ml-2">
                        <i class="fa fa-paper-plane-o"></i> Load
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
