<h2>Add Password</h2>
<form #addPasswordForm="ngForm">
  <div class="form-horizontal">
    <hr />
    <div class="form-group">
      <label for="Password" class="control-label col-md-3">Password</label>
      <div class="col-md-9" [ngClass]="{'has-error': Password.dirty && !Password.invalid}">
        <input id="Password" name="Password" type="password" [(ngModel)]="model.Password" #Password="ngModel" class="form-control" required />
      </div>
    </div>
    <div class="form-group">
      <label for="ConfirmPassword" class="control-label col-md-3">Confirm password</label>
      <div class="col-md-9" [ngClass]="{'has-error': ConfirmPassword.dirty && !ConfirmPassword.invalid}">
        <input id="ConfirmPassword" name="ConfirmPassword" type="password" [(ngModel)]="model.ConfirmPassword" #ConfirmPassword="ngModel" class="form-control" required />
      </div>
    </div>
    <div class="form-group">
      <div class="col-md-offset-3 col-md-9">
        <button (click)="addPassword()" [disabled]="addPasswordForm.form.invalid || isBusy" type="button" class="btn btn-primary">
          Save
        </button>
        <button (click)="cancel()" [disabled]="isBusy" type="button" class="btn btn-default">
          Cancel
        </button>
      </div>
    </div>
  </div>
</form>
