import * as tslib_1 from "tslib";
import { Element as CoreElement, ElementFieldDataType } from "@forcrowd/backbone-client-core";
var Element = /** @class */ (function (_super) {
    tslib_1.__extends(Element, _super);
    function Element() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.fields = {
            parent: null,
            familyTree: null,
            rating: 0,
            income: 0
        };
        return _this;
    }
    Element.prototype.elementFieldSet = function (ratingEnabledFilter) {
        if (ratingEnabledFilter === void 0) { ratingEnabledFilter = true; }
        return this.getElementFieldSetWithFilter(this, ratingEnabledFilter);
    };
    Element.prototype.familyTree = function () {
        // TODO In case of add / remove elements?
        if (this.fields.familyTree === null) {
            this.setFamilyTree();
        }
        return this.fields.familyTree;
    };
    Element.prototype.getElementFieldSetSorted = function () {
        return this.ElementFieldSet.sort(function (a, b) { return a.SortOrder - b.SortOrder; });
    };
    Element.prototype.getElementItemSet = function (sort) {
        if (sort === void 0) { sort = "name"; }
        return this.ElementItemSet.sort(function (a, b) {
            switch (sort) {
                case "income":
                default: {
                    return b.income() - a.income();
                }
                case "name": {
                    var nameA = a.Name.toLowerCase();
                    var nameB = b.Name.toLowerCase();
                    if (nameA < nameB)
                        return -1;
                    if (nameA > nameB)
                        return 1;
                    return 0;
                }
                case "allRoundsIncome": {
                    return b.allRoundsIncome() - a.allRoundsIncome();
                }
            }
        });
    };
    Element.prototype.income = function () {
        return this.fields.income;
    };
    Element.prototype.rating = function () {
        return this.fields.rating;
    };
    Element.prototype.initialize = function () {
        if (this.initialized)
            return;
        _super.prototype.initialize.call(this);
        // Fields
        this.ElementFieldSet.forEach(function (field) {
            field.initialize();
        });
        // Items
        this.ElementItemSet.forEach(function (item) {
            item.initialize();
        });
    };
    Element.prototype.parent = function () {
        // TODO In case of add / remove elements?
        if (this.fields.parent === null) {
            this.setParent();
        }
        return this.fields.parent;
    };
    Element.prototype.setFamilyTree = function () {
        this.fields.familyTree = [];
        var element = this; // TODO: ?
        while (element) {
            this.fields.familyTree.unshift(element);
            element = element.parent();
        }
        // TODO At the moment it's only upwards, later include children?
    };
    Element.prototype.setIncome = function () {
        var value = 0;
        this.ElementItemSet.forEach(function (item) {
            value += item.income();
        });
        if (this.fields.income !== value) {
            this.fields.income = value;
        }
    };
    Element.prototype.setParent = function () {
        if (this.ParentFieldSet.length > 0) {
            this.fields.parent = this.ParentFieldSet[0].Element;
        }
    };
    Element.prototype.setRating = function () {
        var fieldSet = this.elementFieldSet(false);
        var value = 0;
        fieldSet.forEach(function (field) {
            value += field.rating();
        });
        if (this.fields.rating !== value) {
            this.fields.rating = value;
            // Update related
            fieldSet.forEach(function (field) {
                field.setRatingPercentage();
            });
        }
    };
    Element.prototype.getElementFieldSetWithFilter = function (element, ratingEnabledFilter) {
        var _this = this;
        if (ratingEnabledFilter === void 0) { ratingEnabledFilter = true; }
        var sortedElementFieldSet = element.getElementFieldSetSorted();
        var fieldSet = [];
        // Validate
        sortedElementFieldSet.forEach(function (field) {
            if (!ratingEnabledFilter || (ratingEnabledFilter && field.RatingEnabled)) {
                fieldSet.push(field);
            }
            if (field.DataType === ElementFieldDataType.Element && field.SelectedElement !== null) {
                var childFieldSet = _this.getElementFieldSetWithFilter(field.SelectedElement, ratingEnabledFilter);
                childFieldSet.forEach(function (childField) {
                    fieldSet.push(childField);
                });
            }
        });
        return fieldSet;
    };
    return Element;
}(CoreElement));
export { Element };
