import * as tslib_1 from "tslib";
import { Project as CoreProject } from "@forcrowd/backbone-client-core";
import { Subject } from "rxjs";
export var RatingMode;
(function (RatingMode) {
    RatingMode[RatingMode["CurrentUser"] = 1] = "CurrentUser";
    RatingMode[RatingMode["AllUsers"] = 2] = "AllUsers";
})(RatingMode || (RatingMode = {}));
var Project = /** @class */ (function (_super) {
    tslib_1.__extends(Project, _super);
    function Project() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Client-side
        _this.initialValue = 100;
        _this.ratingModeUpdated = new Subject();
        _this.fields = {
            ratingMode: RatingMode.CurrentUser,
            rounds: 0,
        };
        return _this;
    }
    Object.defineProperty(Project.prototype, "RatingMode", {
        get: function () {
            return this.fields.ratingMode;
        },
        set: function (value) {
            if (this.fields.ratingMode !== value) {
                this.fields.ratingMode = value;
                this.ratingModeUpdated.next(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "rounds", {
        get: function () {
            return this.fields.rounds;
        },
        enumerable: true,
        configurable: true
    });
    Project.prototype.increaseRounds = function () {
        this.fields.rounds++;
        this.ElementSet.forEach(function (element) {
            element.ElementItemSet.forEach(function (elementItem) {
                elementItem.ElementCellSet.forEach(function (elementCell) {
                    elementCell.setIncome();
                    elementCell.increaseAllRoundsIncome();
                });
                elementItem.increaseAllRoundsIncome();
            });
        });
    };
    Project.prototype.resetRounds = function () {
        this.fields.rounds = 0;
        this.ElementSet.forEach(function (element) {
            element.ElementItemSet.forEach(function (elementItem) {
                elementItem.ElementCellSet.forEach(function (elementCell) {
                    elementCell.resetAllRoundsIncome();
                });
                elementItem.resetAllRoundsIncome();
            });
        });
    };
    Project.prototype.initialize = function () {
        if (this.initialized)
            return;
        _super.prototype.initialize.call(this);
        // Elements sort order
        this.ElementSet = this.ElementSet.sort(function (a, b) { return a.SortOrder - b.SortOrder; });
        this.ElementSet.forEach(function (element) {
            element.initialize();
        });
    };
    Project.prototype.toggleRatingMode = function () {
        this.RatingMode = this.RatingMode === RatingMode.CurrentUser
            ? RatingMode.AllUsers
            : RatingMode.CurrentUser;
    };
    return Project;
}(CoreProject));
export { Project };
