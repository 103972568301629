import { HttpClient } from "@angular/common/http";
import { AuthService } from "@forcrowd/backbone-client-core";
import { map } from "rxjs/operators";
import { AppSettings } from "../../app-settings/app-settings";
var AccountService = /** @class */ (function () {
    function AccountService(authService, httpClient) {
        this.authService = authService;
        this.httpClient = httpClient;
        // Service urls
        this.addPasswordUrl = "";
        this.changeEmailUrl = "";
        this.changePasswordUrl = "";
        this.changeUserNameUrl = "";
        this.confirmEmailUrl = "";
        this.resendConfirmationEmailUrl = "";
        this.resetPasswordUrl = "";
        this.resetPasswordRequestUrl = "";
        this.appHttpClient = null;
        this.appHttpClient = httpClient;
        // Service urls
        this.addPasswordUrl = AppSettings.serviceApiUrl + "/Account/AddPassword";
        this.changeEmailUrl = AppSettings.serviceApiUrl + "/Account/ChangeEmail";
        this.changePasswordUrl = AppSettings.serviceApiUrl + "/Account/ChangePassword";
        this.changeUserNameUrl = AppSettings.serviceApiUrl + "/Account/ChangeUserName";
        this.confirmEmailUrl = AppSettings.serviceApiUrl + "/Account/ConfirmEmail";
        this.resendConfirmationEmailUrl = AppSettings.serviceApiUrl + "/Account/ResendConfirmationEmail";
        this.resetPasswordUrl = AppSettings.serviceApiUrl + "/Account/ResetPassword";
        this.resetPasswordRequestUrl = AppSettings.serviceApiUrl + "/Account/ResetPasswordRequest";
    }
    Object.defineProperty(AccountService.prototype, "isBusy", {
        get: function () {
            return this.appHttpClient.isBusy;
        },
        enumerable: true,
        configurable: true
    });
    AccountService.prototype.addPassword = function (addPasswordBindingModel) {
        var _this = this;
        return this.httpClient.post(this.addPasswordUrl, addPasswordBindingModel).pipe(map(function (updatedUser) {
            _this.authService.updateCurrentUser(updatedUser);
        }));
    };
    AccountService.prototype.changeEmail = function (changeEmailBindingModel) {
        var _this = this;
        changeEmailBindingModel.ClientAppUrl = window.location.origin;
        return this.httpClient.post(this.changeEmailUrl, changeEmailBindingModel).pipe(map(function (updatedUser) {
            _this.authService.updateCurrentUser(updatedUser);
        }));
    };
    AccountService.prototype.changePassword = function (changePasswordBindingModel) {
        var _this = this;
        return this.httpClient.post(this.changePasswordUrl, changePasswordBindingModel).pipe(map(function (updatedUser) {
            _this.authService.updateCurrentUser(updatedUser);
        }));
    };
    AccountService.prototype.changeUserName = function (changeUserNameBindingModel) {
        var _this = this;
        return this.httpClient.post(this.changeUserNameUrl, changeUserNameBindingModel).pipe(map(function (updatedUser) {
            _this.authService.updateCurrentUser(updatedUser);
        }));
    };
    AccountService.prototype.confirmEmail = function (confirmEmailBindingModel) {
        var _this = this;
        return this.httpClient.post(this.confirmEmailUrl, confirmEmailBindingModel).pipe(map(function (updatedUser) {
            _this.authService.updateCurrentUser(updatedUser);
        }));
    };
    AccountService.prototype.resendConfirmationEmail = function () {
        var model = { ClientAppUrl: window.location.origin };
        return this.httpClient.post(this.resendConfirmationEmailUrl, model);
    };
    AccountService.prototype.resetPassword = function (resetPasswordBindingModel) {
        var _this = this;
        return this.httpClient.post(this.resetPasswordUrl, resetPasswordBindingModel).pipe(map(function (updatedUser) {
            _this.authService.updateCurrentUser(updatedUser);
        }));
    };
    AccountService.prototype.resetPasswordRequest = function (resetPasswordRequestBindingModel) {
        resetPasswordRequestBindingModel.ClientAppUrl = window.location.origin;
        return this.httpClient.post(this.resetPasswordRequestUrl, resetPasswordRequestBindingModel);
    };
    return AccountService;
}());
export { AccountService };
