/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./introduction.component";
var styles_IntroductionComponent = [];
var RenderType_IntroductionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IntroductionComponent, data: {} });
export { RenderType_IntroductionComponent as RenderType_IntroductionComponent };
export function View_IntroductionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Introduction "])), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 5, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "bs-callout bs-callout-info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Can we distribute our monetary resources to organizations by measuring their benefits to society? "])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Wealth Framework is an experimental project that aims to present ideas on creating a more sustainable and productive economic system, by empowering the organizations that are more beneficial to the society. "]))], null, null); }
export function View_IntroductionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "introduction", [], null, null, null, View_IntroductionComponent_0, RenderType_IntroductionComponent)), i0.ɵdid(1, 49152, null, 0, i1.IntroductionComponent, [], null, null)], null, null); }
var IntroductionComponentNgFactory = i0.ɵccf("introduction", i1.IntroductionComponent, View_IntroductionComponent_Host_0, {}, {}, []);
export { IntroductionComponentNgFactory as IntroductionComponentNgFactory };
