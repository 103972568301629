import { timer as observableTimer } from "rxjs";
import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountService } from "./account.service";
import { Logger } from "../logger/logger.module";
var ConfirmEmailComponent = /** @class */ (function () {
    function ConfirmEmailComponent(activatedRoute, accountService, logger, router) {
        this.activatedRoute = activatedRoute;
        this.accountService = accountService;
        this.logger = logger;
        this.router = router;
    }
    Object.defineProperty(ConfirmEmailComponent.prototype, "isBusy", {
        get: function () {
            return this.accountService.isBusy;
        },
        enumerable: true,
        configurable: true
    });
    ConfirmEmailComponent.prototype.resendConfirmationEmail = function () {
        var _this = this;
        this.accountService.resendConfirmationEmail()
            .subscribe(function () {
            _this.logger.logSuccess("Confirmation email has been resent to your email address!");
        });
    };
    ConfirmEmailComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Todo This timer silliness is necessary probably cos of this issue: https://github.com/angular/angular/issues/15634
        observableTimer(0).subscribe(function () {
            // Get token
            var token = _this.activatedRoute.snapshot.params["token"];
            // Validate
            if (!token) {
                return;
            }
            // Confirm
            _this.accountService.confirmEmail({ Token: token })
                .subscribe(function () {
                _this.logger.logSuccess("Your email address has been confirmed!");
                _this.router.navigate(["/app/account"]);
            });
        });
    };
    return ConfirmEmailComponent;
}());
export { ConfirmEmailComponent };
