import { BodyOutputType, ToasterConfig, ToasterService } from "angular2-toaster";
var Logger = /** @class */ (function () {
    function Logger(toasterService) {
        this.toasterService = toasterService;
        this.toasterConfigured = false;
    }
    Logger.prototype.getToasterConfig = function () {
        this.toasterConfigured = true;
        return new ToasterConfig({
            bodyOutputType: BodyOutputType.TrustedHtml,
            positionClass: "toast-bottom-right"
        });
    };
    Logger.prototype.log = function (message, showToast) {
        var optionalParams = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            optionalParams[_i - 2] = arguments[_i];
        }
        return this.logIt.apply(this, [message, showToast, "log"].concat(optionalParams));
    };
    Logger.prototype.logError = function (message, showToast) {
        var optionalParams = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            optionalParams[_i - 2] = arguments[_i];
        }
        return this.logIt.apply(this, [message, showToast, "error"].concat(optionalParams));
    };
    Logger.prototype.logInfo = function (message, showToast) {
        var optionalParams = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            optionalParams[_i - 2] = arguments[_i];
        }
        return this.logIt.apply(this, [message, showToast, "info"].concat(optionalParams));
    };
    Logger.prototype.logSuccess = function (message, showToast) {
        var optionalParams = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            optionalParams[_i - 2] = arguments[_i];
        }
        return this.logIt.apply(this, [message, showToast, "success"].concat(optionalParams));
    };
    Logger.prototype.logWarning = function (message, showToast) {
        var optionalParams = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            optionalParams[_i - 2] = arguments[_i];
        }
        return this.logIt.apply(this, [message, showToast, "warning"].concat(optionalParams));
    };
    Logger.prototype.logIt = function (message, showToast, toastType) {
        if (showToast === void 0) { showToast = true; }
        if (toastType === void 0) { toastType = "log"; }
        var optionalParams = [];
        for (var _i = 3; _i < arguments.length; _i++) {
            optionalParams[_i - 3] = arguments[_i];
        }
        var write;
        switch (toastType) {
            case "error":
                write = console.error;
                break;
            case "info":
                write = console.info;
                break;
            case "log":
                write = console.log;
                break;
            case "success":
                write = console.log;
                break;
            case "warning":
                write = console.warn;
                break;
        }
        write.apply(void 0, [message].concat(optionalParams));
        if (showToast && this.toasterConfigured) {
            switch (toastType) {
                case "error":
                    this.toasterService.pop("error", "", message);
                    break;
                case "info":
                    this.toasterService.pop("info", "", message);
                    break;
                case "log":
                    this.toasterService.pop("info", "", message);
                    break;
                case "success":
                    this.toasterService.pop("success", "", message);
                    break;
                case "warning":
                    this.toasterService.pop("warning", "", message);
                    break;
            }
        }
    };
    return Logger;
}());
export { Logger };
