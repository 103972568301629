import { Routes } from "@angular/router";
import { ICoreConfig as IBackboneConfig } from "@forcrowd/backbone-client-core";
import { environment } from "../../app-settings/environments/environment-settings";
import { Element, ElementCell, ElementField, ElementItem, Project, 
// Role,
// User,
// UserClaim,
UserElementCell, UserElementField, } from "./entities";
// Components
import { ContributorsComponent } from "./components/contributors.component";
import { HomeComponent } from "./components/home.component";
import { NotFoundComponent } from "./components/not-found.component";
import { ProjectViewerComponent } from "./components/project-viewer.component";
// Components - not in use
//import { BasicsComponent } from "./components/basics.component";
//import { ImplementationComponent } from "./components/implementation.component";
//import { ReasonComponent } from "./components/reason.component";
//import { TotalCostIndexComponent } from "./components/total-cost-index.component";
// Services
import { AppProjectService } from "./app-project.service";
import { AuthGuard } from "./auth-guard.service";
import { CanDeactivateGuard } from "./can-deactivate-guard.service";
import { DynamicTitleResolve } from "./dynamic-title-resolve.service";
export { AppProjectService, AuthGuard, CanDeactivateGuard, DynamicTitleResolve };
var ɵ0 = { title: "Home" }, ɵ1 = { title: "Contributors" }, ɵ2 = { title: "Not Found" }, ɵ3 = { title: "Project" };
// Routes
var coreRoutes = [
    { path: "", component: HomeComponent, data: ɵ0 },
    { path: "app/home", redirectTo: "", pathMatch: "full" },
    { path: "app/contributors", component: ContributorsComponent, data: ɵ1 },
    { path: "app/not-found", component: NotFoundComponent, data: ɵ2 },
    { path: "project/:project-id", component: ProjectViewerComponent, data: ɵ3 },
];
// Backbone config
var backboneConfig = {
    environment: environment.name,
    serviceApiUrl: environment.serviceAppUrl + "/api/v1",
    serviceODataUrl: environment.serviceAppUrl + "/odata/v1",
    entityManagerConfig: {
        elementType: Element,
        elementCellType: ElementCell,
        elementFieldType: ElementField,
        elementItemType: ElementItem,
        projectType: Project,
        roleType: null,
        userType: null,
        userClaimType: null,
        userElementCellType: UserElementCell,
        userElementFieldType: UserElementField,
        userLoginType: null,
        userRoleType: null
    }
};
var CoreModule = /** @class */ (function () {
    function CoreModule() {
    }
    return CoreModule;
}());
export { CoreModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
