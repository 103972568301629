import { OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { getUniqueEmail, getUniqueUserName, stripInvalidChars } from "@forcrowd/backbone-client-core";
import { AuthService } from "@forcrowd/backbone-client-core";
import { AppSettings } from "../../app-settings/app-settings";
import { Logger } from "../logger/logger.module";
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent(authService, logger, router) {
        this.authService = authService;
        this.logger = logger;
        this.router = router;
        this.bindingModel = {
            get UserName() {
                return this.fields.userName;
            },
            set UserName(value) {
                this.fields.userName = stripInvalidChars(value);
            },
            Email: "",
            Password: "",
            ConfirmPassword: "",
            fields: {
                userName: ""
            }
        };
        this.rememberMe = true;
        this.subscriptions = [];
    }
    Object.defineProperty(RegisterComponent.prototype, "isBusy", {
        get: function () {
            return this.authService.isBusy;
        },
        enumerable: true,
        configurable: true
    });
    RegisterComponent.prototype.ngOnInit = function () {
        // Generate test data if localhost
        if (AppSettings.environment === "Development") {
            this.bindingModel.UserName = getUniqueUserName();
            this.bindingModel.Email = getUniqueEmail();
            this.bindingModel.Password = "123qwe";
            this.bindingModel.ConfirmPassword = "123qwe";
        }
    };
    RegisterComponent.prototype.register = function () {
        var _this = this;
        this.authService.register(this.bindingModel, this.rememberMe)
            .subscribe(function () {
            _this.logger.logSuccess("You have been registered!");
            _this.router.navigate(["/app/account/confirm-email"]);
        });
    };
    return RegisterComponent;
}());
export { RegisterComponent };
