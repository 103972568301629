export const environment = {

  /**
   * Name of the current environment
   */
  name: "Production",

  /*
   * Service application (WebApi) url
   */
  serviceAppUrl: "https://api.backbone.forcrowd.org",

  /*
   * Content settings: Project IDs that are used in the content
   */
  content: {
    prologueProjectId: 1127,
    priorityProjectId: 1128,
    knowledgeProjectId: 1129,
    knowledgeLicensesProjectId: 1130,
    allInOneProjectId: 1131,

    sustain2018Project1Id: 1191,
    sustain2018Project2Id: 1192,
  }
};
