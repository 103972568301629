import * as tslib_1 from "tslib";
import { UserElementCell as CoreUserElementCell } from "@forcrowd/backbone-client-core";
var UserElementCell = /** @class */ (function (_super) {
    tslib_1.__extends(UserElementCell, _super);
    function UserElementCell() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.fields = {
            decimalValue: null
        };
        return _this;
    }
    Object.defineProperty(UserElementCell.prototype, "DecimalValue", {
        get: function () {
            return this.fields.decimalValue;
        },
        set: function (value) {
            if (this.fields.decimalValue !== value) {
                this.fields.decimalValue = value;
                // Update related
                if (this.initialized) {
                    this.ElementCell.setCurrentUserDecimalValue();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    UserElementCell.prototype.initialize = function () {
        if (this.initialized)
            return;
        _super.prototype.initialize.call(this);
        this.ElementCell.setCurrentUserDecimalValue();
    };
    return UserElementCell;
}(CoreUserElementCell));
export { UserElementCell };
