import * as tslib_1 from "tslib";
import { ElementCell as CoreElementCell, ElementFieldDataType } from "@forcrowd/backbone-client-core";
import { Subject } from "rxjs";
import { RatingMode } from "./project";
var ElementCell = /** @class */ (function (_super) {
    tslib_1.__extends(ElementCell, _super);
    function ElementCell() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Client
        _this.otherUsersDecimalValueTotal = 0;
        _this.otherUsersDecimalValueCount = 0;
        _this.selectedDecimalValue = 0;
        // Events
        _this.decimalValueUpdated = new Subject();
        _this.fields = {
            allRoundsIncome: 0,
            currentUserDecimalValue: 0,
            income: 0,
            decimalValue: 0,
            decimalValuePercentage: 0,
        };
        return _this;
    }
    ElementCell.prototype.allRoundsIncome = function () {
        return this.fields.allRoundsIncome;
    };
    ElementCell.prototype.income = function () {
        return this.fields.income;
    };
    ElementCell.prototype.increaseAllRoundsIncome = function () {
        this.fields.allRoundsIncome += this.income();
    };
    ElementCell.prototype.initialize = function () {
        var _this = this;
        if (this.initialized)
            return true;
        _super.prototype.initialize.call(this);
        // Other users'
        this.otherUsersDecimalValueTotal = this.DecimalValueTotal;
        this.otherUsersDecimalValueCount = this.DecimalValueCount;
        // Exclude current user's
        if (this.UserElementCellSet[0]) {
            this.otherUsersDecimalValueTotal -= this.UserElementCellSet[0].DecimalValue;
            this.otherUsersDecimalValueCount -= 1;
        }
        // User cells
        this.UserElementCellSet.forEach(function (userCell) {
            userCell.initialize();
        });
        // Initial values
        this.setCurrentUserDecimalValue();
        // Event handlers
        this.ElementField.Element.Project.ratingModeUpdated.subscribe(function () {
            _this.ElementField.Element.Project.RatingMode === RatingMode.CurrentUser
                ? _this.currentUserDecimalValue()
                : _this.allUsersDecimalValue();
        });
        return true;
    };
    ElementCell.prototype.decimalValue = function () {
        return this.fields.decimalValue;
    };
    ElementCell.prototype.decimalValueAverage = function () {
        return this.decimalValueCount() === 0 ? 0 : this.decimalValueTotal() / this.decimalValueCount();
    };
    ElementCell.prototype.decimalValueCount = function () {
        return this.ElementField.UseFixedValue
            ? 1
            : this.otherUsersDecimalValueCount + 1; // There is always default value, increase count by 1
    };
    ElementCell.prototype.decimalValuePercentage = function () {
        return this.fields.decimalValuePercentage;
    };
    ElementCell.prototype.decimalValueTotal = function () {
        return this.ElementField.UseFixedValue
            ? this.UserElementCellSet[0]
                ? this.currentUserDecimalValue()
                : this.otherUsersDecimalValueTotal
            : this.otherUsersDecimalValueTotal + this.currentUserDecimalValue();
    };
    ElementCell.prototype.resetAllRoundsIncome = function () {
        this.fields.allRoundsIncome = 0;
    };
    ElementCell.prototype.setCurrentUserDecimalValue = function () {
        var value = this.UserElementCellSet[0] ? this.UserElementCellSet[0].DecimalValue : 50; // Default value
        if (this.fields.currentUserDecimalValue !== value) {
            this.fields.currentUserDecimalValue = value;
            this.currentUserDecimalValue();
        }
    };
    ElementCell.prototype.setIncome = function () {
        var value = 0; // Default value?
        if (this.ElementField.DataType === ElementFieldDataType.Element && this.SelectedElementItem !== null) {
            // item's index income / how many times this item has been selected (used) by higher items
            // TODO Check whether ParentCellSet gets updated when selecting / deselecting an item
            value = this.SelectedElementItem.income() / this.SelectedElementItem.ParentCellSet.length;
        }
        else {
            if (this.ElementField.RatingEnabled) {
                value = this.ElementField.income() * this.decimalValuePercentage();
            }
        }
        if (this.fields.income !== value) {
            this.fields.income = value;
            // Update related
            this.ElementItem.setIncome();
        }
    };
    ElementCell.prototype.currentUserDecimalValue = function () {
        if (this.fields.decimalValue !== this.fields.currentUserDecimalValue) {
            this.fields.decimalValue = this.fields.currentUserDecimalValue;
            // Update related
            //this.setDecimalValuePercentage(); - No need to call this one since field is going to update it anyway! / coni2k - 05 Nov. '17
            this.ElementField.setDecimalValue();
            // Event
            this.decimalValueUpdated.next(this.fields.decimalValue);
        }
        return this.fields.currentUserDecimalValue;
    };
    ElementCell.prototype.allUsersDecimalValue = function () {
        if (this.fields.decimalValue !== this.decimalValueAverage()) {
            this.fields.decimalValue = this.decimalValueAverage();
            // Update related
            this.ElementField.setDecimalValue();
            // Event
            this.decimalValueUpdated.next(this.fields.decimalValue);
        }
    };
    ElementCell.prototype.setDecimalValuePercentage = function () {
        var value = this.ElementField.decimalValue() === 0
            ? 0
            : this.decimalValue() / this.ElementField.decimalValue();
        if (this.fields.decimalValuePercentage !== value) {
            this.fields.decimalValuePercentage = value;
            // Update related
            this.setIncome();
        }
    };
    return ElementCell;
}(CoreElementCell));
export { ElementCell };
