import * as tslib_1 from "tslib";
import { ElementFieldDataType, ElementItem as CoreElementItem } from "@forcrowd/backbone-client-core";
import { Subject } from "rxjs";
var ElementItem = /** @class */ (function (_super) {
    tslib_1.__extends(ElementItem, _super);
    function ElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.allRoundsIncomeUpdated = new Subject();
        _this.incomeUpdated = new Subject();
        // Client-side
        _this.fields = {
            allRoundsIncome: 0,
            income: 0,
        };
        return _this;
    }
    ElementItem.prototype.allRoundsIncome = function () {
        return this.fields.allRoundsIncome;
    };
    ElementItem.prototype.elementCell = function (fieldName) {
        var cell = null;
        for (var elementCellIndex = 0; elementCellIndex < this.ElementCellSet.length; elementCellIndex++) {
            cell = this.ElementCellSet[elementCellIndex];
            if (cell.ElementField.Name === fieldName) {
                break;
            }
        }
        return cell;
    };
    ElementItem.prototype.getElementCellSetSorted = function () {
        return this.ElementCellSet.sort(function (a, b) { return (a.ElementField.SortOrder - b.ElementField.SortOrder); });
    };
    ElementItem.prototype.income = function () {
        return this.fields.income;
    };
    ElementItem.prototype.incomeStatus = function () {
        var value = 0; // for element allRoundsIncome;
        var notSelected = 0; // if item isn't selectedElementItem then increase
        this.Element.ElementItemSet.forEach(function (item) {
            if (item.ParentCellSet.length === 0)
                notSelected++;
            value += item.allRoundsIncome();
        });
        // Probably Element is Parent
        if (this.Element.ElementItemSet.length === notSelected)
            notSelected = 0;
        var incomeAllRounds = +(this.allRoundsIncome().toFixed(2));
        var allRoundsIncomeAverage = +((value / (this.Element.ElementItemSet.length - notSelected)).toFixed(2));
        if (incomeAllRounds < allRoundsIncomeAverage) {
            return "low";
        }
        else if (incomeAllRounds > allRoundsIncomeAverage) {
            return "high";
        }
        return "average";
    };
    ElementItem.prototype.increaseAllRoundsIncome = function () {
        this.fields.allRoundsIncome += this.income();
        this.allRoundsIncomeUpdated.next(this.fields.allRoundsIncome);
    };
    ElementItem.prototype.initialize = function () {
        if (this.initialized)
            return;
        _super.prototype.initialize.call(this);
        // Cells
        this.ElementCellSet.forEach(function (cell) {
            cell.initialize();
        });
    };
    ElementItem.prototype.ratingCells = function () {
        return this.getRatingCells(this);
    };
    ElementItem.prototype.resetAllRoundsIncome = function () {
        this.fields.allRoundsIncome = 0;
        this.allRoundsIncomeUpdated.next(this.fields.allRoundsIncome);
    };
    ElementItem.prototype.setIncome = function () {
        var value = 0;
        this.ElementCellSet.forEach(function (cell) {
            value += cell.income();
        });
        if (this.fields.income !== value) {
            this.fields.income = value;
            // Update related
            // TODO Is this correct? It looks like it didn't affect anything?
            this.ParentCellSet.forEach(function (parentCell) {
                parentCell.setIncome();
            });
            this.Element.setIncome();
            this.incomeUpdated.next(this.fields.income);
        }
    };
    ElementItem.prototype.getRatingCells = function (elementItem) {
        var _this = this;
        var ratingCells = [];
        var sortedElementCellSet = elementItem.getElementCellSetSorted();
        sortedElementCellSet.forEach(function (cell) {
            if (cell.ElementField.RatingEnabled) {
                ratingCells.push(cell);
            }
            if (cell.ElementField.DataType === ElementFieldDataType.Element && cell.SelectedElementItem !== null) {
                var childRatingCells = _this.getRatingCells(cell.SelectedElementItem);
                if (childRatingCells.length > 0) {
                    ratingCells.push(cell);
                }
            }
        });
        return ratingCells;
    };
    return ElementItem;
}(CoreElementItem));
export { ElementItem };
