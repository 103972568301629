import * as tslib_1 from "tslib";
import { ProjectService } from "@forcrowd/backbone-client-core";
import { Subject } from "rxjs";
var AppProjectService = /** @class */ (function (_super) {
    tslib_1.__extends(AppProjectService, _super);
    function AppProjectService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.elementCellDecimalValueUpdated = new Subject();
        return _this;
    }
    AppProjectService.prototype.updateElementCellDecimalValue = function (elementCell, value) {
        var userElementCell = elementCell.UserElementCellSet[0];
        if (!userElementCell) {
            this.createUserElementCell(elementCell, value);
        }
        else {
            userElementCell.DecimalValue = value;
        }
        this.elementCellDecimalValueUpdated.next(elementCell);
    };
    AppProjectService.prototype.updateElementFieldRating = function (elementField, rating) {
        var userElementField = elementField.UserElementFieldSet[0];
        if (!userElementField) {
            this.createUserElementField(elementField, rating);
        }
        else {
            userElementField.Rating = rating;
        }
    };
    return AppProjectService;
}(ProjectService));
export { AppProjectService };
