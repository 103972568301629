import * as tslib_1 from "tslib";
import { UserElementField as CoreUserElementField } from "@forcrowd/backbone-client-core";
var UserElementField = /** @class */ (function (_super) {
    tslib_1.__extends(UserElementField, _super);
    function UserElementField() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._rating = 0;
        return _this;
    }
    Object.defineProperty(UserElementField.prototype, "Rating", {
        get: function () {
            return this._rating;
        },
        set: function (value) {
            if (this._rating !== value) {
                this._rating = value;
                // Update related
                if (this.initialized) {
                    this.ElementField.setCurrentUserRating();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    UserElementField.prototype.initialize = function () {
        if (this.initialized)
            return;
        _super.prototype.initialize.call(this);
        this.ElementField.setCurrentUserRating();
    };
    return UserElementField;
}(CoreUserElementField));
export { UserElementField };
