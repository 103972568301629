import * as tslib_1 from "tslib";
import { ElementField as CoreElementField, ElementFieldDataType } from "@forcrowd/backbone-client-core";
import { Subject } from "rxjs";
import { RatingMode } from "./project";
var ElementField = /** @class */ (function (_super) {
    tslib_1.__extends(ElementField, _super);
    function ElementField() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.otherUsersRatingTotal = 0;
        _this.otherUsersRatingCount = 0;
        _this.ratingUpdated = new Subject();
        _this.fields = {
            currentUserRating: 0,
            decimalValue: 0,
            income: 0,
            rating: 0,
            ratingPercentage: 0,
        };
        return _this;
    }
    Object.defineProperty(ElementField.prototype, "DataTypeText", {
        // Client-side
        get: function () {
            var text = ElementFieldDataType[this.DataType];
            if (this.DataType === ElementFieldDataType.Element && this.SelectedElement) {
                text += " - " + this.SelectedElement.Name;
            }
            return text;
        },
        enumerable: true,
        configurable: true
    });
    ElementField.prototype.currentUserRating = function () {
        return this.fields.currentUserRating;
    };
    ElementField.prototype.income = function () {
        return this.fields.income;
    };
    ElementField.prototype.rating = function () {
        return this.fields.rating;
    };
    ElementField.prototype.ratingAverage = function () {
        return this.ratingCount() === 0 ?
            0 :
            this.ratingTotal() / this.ratingCount();
    };
    ElementField.prototype.ratingCount = function () {
        return this.otherUsersRatingCount + 1; // There is always default value, increase count by 1
    };
    ElementField.prototype.ratingPercentage = function () {
        return this.fields.ratingPercentage;
    };
    ElementField.prototype.ratingTotal = function () {
        return this.otherUsersRatingTotal + this.currentUserRating();
    };
    ElementField.prototype.initialize = function () {
        var _this = this;
        if (this.initialized)
            return;
        _super.prototype.initialize.call(this);
        // Cells
        this.ElementCellSet.forEach(function (cell) {
            cell.initialize();
        });
        // Other users'
        this.otherUsersRatingTotal = this.RatingTotal;
        this.otherUsersRatingCount = this.RatingCount;
        // Exclude current user's
        if (this.UserElementFieldSet[0]) {
            this.otherUsersRatingTotal -= this.UserElementFieldSet[0].Rating;
            this.otherUsersRatingCount -= 1;
        }
        // User fields
        this.UserElementFieldSet.forEach(function (userField) {
            userField.initialize();
        });
        // Initial values
        this.setCurrentUserRating();
        // Event handlers
        this.Element.Project.ratingModeUpdated.subscribe(function () {
            _this.setRating();
        });
    };
    ElementField.prototype.decimalValue = function () {
        return this.fields.decimalValue;
    };
    ElementField.prototype.setCurrentUserRating = function () {
        var value = this.UserElementFieldSet[0]
            ? this.UserElementFieldSet[0].Rating
            : this.RatingEnabled
                ? 50 // Default value for RatingEnabled
                : 0; // Otherwise 0
        if (this.fields.currentUserRating !== value) {
            this.fields.currentUserRating = value;
            // Update related
            this.setRating();
        }
    };
    ElementField.prototype.setIncome = function () {
        var value = this.Element.familyTree()[0].Project.initialValue * this.ratingPercentage();
        if (this.fields.income !== value) {
            this.fields.income = value;
            // Update related
            this.ElementCellSet.forEach(function (cell) {
                cell.setIncome();
            });
        }
    };
    ElementField.prototype.setRating = function () {
        var value = 0; // Default value
        switch (this.Element.Project.RatingMode) {
            case RatingMode.CurrentUser: {
                value = this.currentUserRating();
                break;
            }
            case RatingMode.AllUsers: {
                value = this.ratingAverage();
                break;
            }
        }
        if (this.fields.rating !== value) {
            this.fields.rating = value;
            // Update related
            //this.ratingPercentage(); - No need to call this one since element is going to update it anyway! / coni2k - 05 Nov. '17 
            this.Element.familyTree()[0].setRating();
            this.ratingUpdated.next(this.fields.rating);
        }
    };
    ElementField.prototype.setRatingPercentage = function () {
        var elementRating = this.Element.familyTree()[0].rating();
        var value = elementRating === 0 ? 0 : this.rating() / elementRating;
        if (this.fields.ratingPercentage !== value) {
            this.fields.ratingPercentage = value;
            // Update related
            this.setIncome();
        }
    };
    ElementField.prototype.setDecimalValue = function () {
        var value = 0;
        this.ElementCellSet.forEach(function (cell) {
            value += cell.decimalValue();
        });
        if (this.fields.decimalValue !== value) {
            this.fields.decimalValue = value;
            // Update related
            this.ElementCellSet.forEach(function (cell) {
                cell.setDecimalValuePercentage();
            });
        }
    };
    return ElementField;
}(CoreElementField));
export { ElementField };
