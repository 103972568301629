var ChartConfig = /** @class */ (function () {
    function ChartConfig(options, data) {
        this.fields = {
            data: null,
            options: null,
        };
        // Validate: Only supports "column" and "pie" type
        if (!(options.chart.type === "column" || options.chart.type === "pie")) {
            var message = "Unsupported chart type: " + this.options.chart.type + " - Only 'column' and 'pie' types supported";
            throw new Error(message);
        }
        // Todo Other validations?
        this.fields.data = data;
        this.fields.options = options;
        this.init();
    }
    Object.defineProperty(ChartConfig.prototype, "data", {
        get: function () {
            return this.fields.data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChartConfig.prototype, "options", {
        get: function () {
            return this.fields.options;
        },
        enumerable: true,
        configurable: true
    });
    ChartConfig.prototype.init = function () {
        // Default values
        this.options.credits = this.options.credits || {};
        this.options.credits.enabled = this.options.credits.enabled || false;
        this.options.plotOptions = this.options.plotOptions || {};
        this.options.plotOptions.column = this.options.plotOptions.column || {};
        this.options.plotOptions.column.allowPointSelect = this.options.plotOptions.column.allowPointSelect || true;
        this.options.plotOptions.column.pointWidth = this.options.plotOptions.column.pointWidth || 15;
        this.options.plotOptions.pie = this.options.plotOptions.pie || {};
        this.options.plotOptions.pie.allowPointSelect = this.options.plotOptions.pie.allowPointSelect || true;
        this.options.plotOptions.pie.cursor = this.options.plotOptions.pie.cursor || "pointer";
        this.options.plotOptions.pie.dataLabels = this.options.plotOptions.pie.dataLabels || {};
        this.options.plotOptions.pie.dataLabels.enabled = this.options.plotOptions.pie.dataLabels.enabled || false;
        this.options.plotOptions.pie.showInLegend = this.options.plotOptions.pie.showInLegend || true;
        this.options.tooltip = this.options.tooltip || {};
        if (!this.options.tooltip.headerFormat && this.options.chart.type === "column") {
            this.options.tooltip.headerFormat = "";
        }
        if (!this.options.tooltip.pointFormat && this.options.chart.type === "pie") {
            this.options.tooltip.pointFormat = "";
        }
        this.options.xAxis = this.options.xAxis || {};
        this.options.xAxis.categories = this.options.xAxis.categories || [""];
        this.options.yAxis = this.options.yAxis || {};
        this.options.yAxis.allowDecimals = this.options.yAxis.allowDecimals || false;
        this.options.yAxis.min = this.options.yAxis.min || 0;
        this.options.title = this.options.title || {};
        this.options.title.text = this.options.title.text || "";
        // Todo series shouldn't be filled outside, data[] is for that purpose
        // Try to handle this case better? / coni2k - 15 Dec. '16
        if (this.options.chart.type === "column") {
            this.options.series = [];
        }
        else if (this.options.chart.type === "pie") {
            var data = [];
            this.options.series = [{ data: data }];
        }
    };
    return ChartConfig;
}());
export { ChartConfig };
