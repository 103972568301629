/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./account-overview.component";
import * as i4 from "@forcrowd/backbone-client-core";
var styles_AccountOverviewComponent = [];
var RenderType_AccountOverviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AccountOverviewComponent, data: {} });
export { RenderType_AccountOverviewComponent as RenderType_AccountOverviewComponent };
function View_AccountOverviewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 35, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 14, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "label", [["class", "control-label col-md-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Email"])), (_l()(), i0.ɵeld(4, 0, null, null, 11, "div", [["class", "col-md-9"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 10, "p", [["class", "form-control-static"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, [" ", " "])), (_l()(), i0.ɵeld(7, 0, null, null, 4, "span", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" | (Unconfirmed) "])), (_l()(), i0.ɵeld(9, 0, null, null, 2, "a", [["routerLink", "/app/account/confirm-email"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Confirm"])), (_l()(), i0.ɵted(-1, null, [" | "])), (_l()(), i0.ɵeld(13, 0, null, null, 2, "a", [["routerLink", "/app/account/change-email"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(14, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Update"])), (_l()(), i0.ɵeld(16, 0, null, null, 8, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 1, "label", [["class", "control-label col-md-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Username"])), (_l()(), i0.ɵeld(19, 0, null, null, 5, "div", [["class", "col-md-9"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 4, "p", [["class", "form-control-static"]], null, null, null, null, null)), (_l()(), i0.ɵted(21, null, [" ", " | "])), (_l()(), i0.ɵeld(22, 0, null, null, 2, "a", [["routerLink", "/app/account/change-username"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 23).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(23, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Update"])), (_l()(), i0.ɵeld(25, 0, null, null, 10, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 1, "label", [["class", "control-label col-md-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Password"])), (_l()(), i0.ɵeld(28, 0, null, null, 7, "div", [["class", "col-md-9"]], null, null, null, null, null)), (_l()(), i0.ɵeld(29, 0, null, null, 6, "p", [["class", "form-control-static"]], null, null, null, null, null)), (_l()(), i0.ɵeld(30, 0, null, null, 2, "a", [["routerLink", "/app/account/change-password"]], [[8, "hidden", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 31).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(31, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Change password"])), (_l()(), i0.ɵeld(33, 0, null, null, 2, "a", [["routerLink", "/app/account/add-password"]], [[8, "hidden", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 34).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(34, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Add password"]))], function (_ck, _v) { var currVal_4 = "/app/account/confirm-email"; _ck(_v, 10, 0, currVal_4); var currVal_7 = "/app/account/change-email"; _ck(_v, 14, 0, currVal_7); var currVal_11 = "/app/account/change-username"; _ck(_v, 23, 0, currVal_11); var currVal_15 = "/app/account/change-password"; _ck(_v, 31, 0, currVal_15); var currVal_19 = "/app/account/add-password"; _ck(_v, 34, 0, currVal_19); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentUser.Email; _ck(_v, 6, 0, currVal_0); var currVal_1 = !_co.displayConfirmEmail; _ck(_v, 7, 0, currVal_1); var currVal_2 = i0.ɵnov(_v, 10).target; var currVal_3 = i0.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_5 = i0.ɵnov(_v, 14).target; var currVal_6 = i0.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_5, currVal_6); var currVal_8 = _co.currentUser.UserName; _ck(_v, 21, 0, currVal_8); var currVal_9 = i0.ɵnov(_v, 23).target; var currVal_10 = i0.ɵnov(_v, 23).href; _ck(_v, 22, 0, currVal_9, currVal_10); var currVal_12 = !_co.currentUser.HasPassword; var currVal_13 = i0.ɵnov(_v, 31).target; var currVal_14 = i0.ɵnov(_v, 31).href; _ck(_v, 30, 0, currVal_12, currVal_13, currVal_14); var currVal_16 = _co.currentUser.HasPassword; var currVal_17 = i0.ɵnov(_v, 34).target; var currVal_18 = i0.ɵnov(_v, 34).href; _ck(_v, 33, 0, currVal_16, currVal_17, currVal_18); }); }
export function View_AccountOverviewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Account Overview"])), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "form-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AccountOverviewComponent_1)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentUser; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_AccountOverviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "account-overview", [], null, null, null, View_AccountOverviewComponent_0, RenderType_AccountOverviewComponent)), i0.ɵdid(1, 49152, null, 0, i3.AccountOverviewComponent, [i4.AuthService], null, null)], null, null); }
var AccountOverviewComponentNgFactory = i0.ɵccf("account-overview", i3.AccountOverviewComponent, View_AccountOverviewComponent_Host_0, {}, {}, []);
export { AccountOverviewComponentNgFactory as AccountOverviewComponentNgFactory };
