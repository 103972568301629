<h2>Reset Password</h2>
<div *ngIf="viewMode === 'initial'">
  <form #resetPasswordRequestForm="ngForm">
    <div class="form-horizontal">
      <hr />
      <div class="form-group">
        <label for="Email" class="control-label col-md-3">Email</label>
        <div class="col-md-9" [ngClass]="{'has-error': Email.dirty && Email.invalid}">
          <input id="Email" name="Email" type="text" [(ngModel)]="requestBindingModel.Email" #Email="ngModel" class="form-control" required />
        </div>
      </div>
      <div class="form-group">
        <div class="col-md-offset-3 col-md-9">
          <button (click)="resetPasswordRequest()" [disabled]="resetPasswordRequestForm.form.invalid || isBusy" type="button" class="btn btn-primary">
            Submit
          </button>
          <!--<button (click)="cancelChanges()" type="button" class="btn btn-danger">
              Cancel
          </button>-->
        </div>
      </div>
    </div>
  </form>
</div>
<div *ngIf="viewMode === 'sent'">
  <p>
    Password reset email has been sent to your address.<br />
    Please use the link in the email to reset your password.
  </p>
</div>
<div *ngIf="viewMode === 'received'">
  <form #resetPasswordForm="ngForm">
    <div class="form-horizontal">
      <hr />
      <div class="form-group">
        <label for="NewPassword" class="control-label col-md-3">New password</label>
        <div class="col-md-9" [ngClass]="{'has-error': NewPassword.dirty && NewPassword.invalid}">
          <input id="NewPassword" name="NewPassword" type="password" [(ngModel)]="bindingModel.NewPassword" #NewPassword="ngModel" class="form-control" required />
        </div>
      </div>
      <div class="form-group">
        <label for="ConfirmPassword" class="control-label col-md-3">Confirm new password</label>
        <div class="col-md-9" [ngClass]="{'has-error': ConfirmPassword.dirty && ConfirmPassword.invalid}">
          <input id="ConfirmPassword" name="ConfirmPassword" type="password" [(ngModel)]="bindingModel.ConfirmPassword" #ConfirmPassword="ngModel" class="form-control" required />
        </div>
      </div>
      <div class="form-group">
        <div class="col-md-offset-3 col-md-9">
          <button (click)="resetPassword()" [disabled]="resetPasswordForm.form.invalid || isBusy" type="button" class="btn btn-primary">
            Submit
          </button>
          <!--<button (click)="cancelChanges()" type="button" class="btn btn-danger">
              Cancel
          </button>-->
        </div>
      </div>
    </div>
  </form>
</div>
