/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../project-viewer/project-viewer.component.ngfactory";
import * as i2 from "../project-viewer/project-viewer.component";
import * as i3 from "@forcrowd/backbone-client-core";
import * as i4 from "../app-project.service";
import * as i5 from "./prologue.component";
var styles_PrologueComponent = [];
var RenderType_PrologueComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PrologueComponent, data: {} });
export { RenderType_PrologueComponent as RenderType_PrologueComponent };
export function View_PrologueComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Prologue: Billion Dollar Question "])), (_l()(), i0.ɵeld(4, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 9, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "bs-callout bs-callout-info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" If you have one billion dollars to spend on making things better in the world, on which subjects would you spend it? "])), (_l()(), i0.ɵeld(9, 0, null, null, 2, "project-viewer", [], null, null, null, i1.View_ProjectViewerComponent_0, i1.RenderType_ProjectViewerComponent)), i0.ɵdid(10, 245760, null, 0, i2.ProjectViewerComponent, [i3.AuthService, i4.AppProjectService], { config: [0, "config"] }, null), i0.ɵpod(11, { initialValue: 0, projectId: 1 }), (_l()(), i0.ɵeld(12, 0, null, null, 2, "p", [["class", "small"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "i", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Today, the society has no way to directly control its resources. We believe, we could solve our global issues much faster we could have a chance to allocate our monetary resources by comparing them. "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 11, 0, 1000000000, _co.appSettings.content.prologueProjectId); _ck(_v, 10, 0, currVal_0); }, null); }
export function View_PrologueComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "prologue", [], null, null, null, View_PrologueComponent_0, RenderType_PrologueComponent)), i0.ɵdid(1, 49152, null, 0, i5.PrologueComponent, [], null, null)], null, null); }
var PrologueComponentNgFactory = i0.ɵccf("prologue", i5.PrologueComponent, View_PrologueComponent_Host_0, {}, {}, []);
export { PrologueComponentNgFactory as PrologueComponentNgFactory };
