/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./not-found.component";
var styles_NotFoundComponent = [];
var RenderType_NotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "page-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Are you lost?"])), (_l()(), i0.ɵeld(3, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 8, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" The page you were looking for appears to have been moved, deleted or does not exist."])), (_l()(), i0.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Wanna go back to our "])), (_l()(), i0.ɵeld(9, 0, null, null, 2, "a", [["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["home"])), (_l()(), i0.ɵted(-1, null, [" page? "]))], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 10).target; var currVal_1 = i0.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_0, currVal_1); }); }
export function View_NotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i0.ɵdid(1, 114688, null, 0, i3.NotFoundComponent, [i1.ActivatedRoute, i1.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotFoundComponentNgFactory = i0.ɵccf("not-found", i3.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
