/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ng-chart.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../node_modules/angular2-highcharts/dist/ChartComponent.ngfactory";
import * as i4 from "angular2-highcharts/dist/HighchartsService";
import * as i5 from "angular2-highcharts/dist/ChartComponent";
import * as i6 from "./ng-chart.component";
var styles_NgChartComponent = [i0.styles];
var RenderType_NgChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NgChartComponent, data: {} });
export { RenderType_NgChartComponent as RenderType_NgChartComponent };
function View_NgChartComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "height": 0, "width": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.chartSize.height, _co.chartSize.width); _ck(_v, 1, 0, currVal_0); }, null); }
function View_NgChartComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "chart", [], null, [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.saveChartObject($event.context) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ChartComponent_0, i3.RenderType_ChartComponent)), i1.ɵprd(512, null, i4.HighchartsService, i4.HighchartsService, [i4.HighchartsStatic]), i1.ɵdid(2, 4243456, null, 3, i5.ChartComponent, [i1.ElementRef, i4.HighchartsService], { options: [0, "options"] }, { load: "load" }), i1.ɵqud(335544320, 1, { series: 0 }), i1.ɵqud(335544320, 2, { xAxis: 0 }), i1.ɵqud(335544320, 3, { yAxis: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.options; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NgChartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NgChartComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NgChartComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.displayChart; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.displayChart; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_NgChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngChart", [], null, null, null, View_NgChartComponent_0, RenderType_NgChartComponent)), i1.ɵdid(1, 114688, null, 0, i6.NgChartComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NgChartComponentNgFactory = i1.ɵccf("ngChart", i6.NgChartComponent, View_NgChartComponent_Host_0, { config: "config" }, {}, []);
export { NgChartComponentNgFactory as NgChartComponentNgFactory };
