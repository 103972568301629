<div class="row">
  <div class="col-md-12">
    <h3>
      Prologue: Billion Dollar Question
    </h3>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="bs-callout bs-callout-info">
      <h4>
        If you have one billion dollars to spend on making things better in the world, on which subjects would you spend it?
      </h4>
    </div>
    <project-viewer [config]="{ initialValue: 1000000000, projectId: appSettings.content.prologueProjectId }"></project-viewer>
    <p class="small">
      <i>
        Today, the society has no way to directly control its resources.
        We believe, we could solve our global issues much faster we could have a chance to allocate our monetary resources by comparing them.
      </i>
    </p>
  </div>
</div>
