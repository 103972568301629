<h2>Change Password</h2>
<form #changePasswordForm="ngForm">
  <div class="form-horizontal">
    <hr />
    <div class="form-group">
      <label for="CurrentPassword" class="control-label col-md-3">Current password</label>
      <div class="col-md-9" [ngClass]="{'has-error': CurrentPassword.dirty && CurrentPassword.invalid}">
        <input id="CurrentPassword" name="CurrentPassword" type="password" [(ngModel)]="bindingModel.CurrentPassword" #CurrentPassword="ngModel" class="form-control" required />
      </div>
    </div>
    <div class="form-group">
      <label for="NewPassword" class="control-label col-md-3">New password</label>
      <div class="col-md-9" [ngClass]="{'has-error': NewPassword.dirty && NewPassword.invalid}">
        <input id="NewPassword" name="NewPassword" type="password" [(ngModel)]="bindingModel.NewPassword" #NewPassword="ngModel" class="form-control" required />
      </div>
    </div>
    <div class="form-group">
      <label for="ConfirmPassword" class="control-label col-md-3">Confirm new password</label>
      <div class="col-md-9" [ngClass]="{'has-error': ConfirmPassword.dirty && ConfirmPassword.invalid}">
        <input id="ConfirmPassword" name="ConfirmPassword" type="password" [(ngModel)]="bindingModel.ConfirmPassword" #ConfirmPassword="ngModel" class="form-control" required />
      </div>
    </div>
    <div class="form-group">
      <div class="col-md-offset-3 col-md-9">
        <button (click)="changePassword()" [disabled]="changePasswordForm.form.invalid || isBusy" type="button" class="btn btn-primary">
          Save
        </button>
        <button (click)="cancel()" [disabled]="isBusy" type="button" class="btn btn-default">
          Cancel
        </button>
      </div>
    </div>
  </div>
</form>
