<!--<div class="jumbotron">
  <h1>Wealth Framework</h1>
  <p class="lead">Experimental ideas on increasing sustainability and productivity</p>
</div>-->
<div class="row">
  <div class="col-md-12">
    <prologue></prologue>
    <hr />
    <introduction></introduction>
    <hr />
    <!--<basics></basics>
    <hr />-->
    <priority-index></priority-index>
    <hr />
    <knowledge-index></knowledge-index>
    <hr />
    <!--<total-cost-index></total-cost-index>
    <hr />-->
    <all-in-one></all-in-one>
    <hr />
    <!--<implementation></implementation>
    <hr />-->
    <!--<reason></reason>-->
  </div>
</div>
