<div class="row">
  <div class="col-md-12">
    <h3>
      Priority Index: Order of Importance
    </h3>
  </div>
</div>
<div class="row">
  <div class="col-md-7">
    <div class="bs-callout bs-callout-info">
      <h4>
        Is it possible to allocate more resources to more important issues?
      </h4>
    </div>
    <p>
      Every organization in the system is trying to solve one of the society's issues; providing food, shelter, healthcare, clothing, education, entertainment and so on.
      However, their importance for society differ.
    </p>
    <p>
      One of the issues in the current system is that the consumers usually interact with only the organizations about their daily needs
      and allocate the resources based on this interaction, instead of their importance.
    </p>
    <p>
      For example, one might think that education is more important for society than most of the other industries.
      On the other side, you should become a consumer of that area of business in order to make transactions, in this case either be a student or a relative of a student.
      If not, it would be out of the routine spending and cannot be supported in a systematic way.
    </p>
    <p>
      <strong>Priority Index</strong> aims to give us the opportunity to directly set each industries' priority by comparing them side by side,
      then distribute the resources to related organizations based on the importance of their business.
    </p>
    <p>
      To make the reasoning behind this new approach clear; if the society could solve its fundamental issues, it would be getting much easier to focus on higher level ones.
      Solve hunger first, solve poverty first, so we will have more people that can contribute to entertainment or produce better cosmetic products.
    </p>
    <p>
      In next example, you will see four main industries. Which ones are more important and should have more resources?
    </p>
  </div>
  <div class="col-md-1">
    <p>&nbsp;</p>
  </div>
  <div class="col-md-4">
    <blockquote class="blockquote-reverse">
      <p>
        ... And so this leads to the paradox that because the disease (malaria) is only in the poorer countries, it doesn't get much investment.
        For example, there's more money put into baldness drugs than are put into malaria. Now, baldness, it's a terrible thing.
        And rich men are afflicted. And so that's why that priority has been set.
      </p>
      <footer>Bill Gates on <cite title="Bill Gates: Mosquitos, malaria and education"><a href="http://go.ted.com/bEub" target="_blank">Mosquitos, malaria and education</a></cite> (2009)</footer>
    </blockquote>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <ul>
      <li>
        Click on <code>Industry</code> link below and rate each item based on their importance.
      </li>
      <li>
        Click on <code>Organization</code> in breadcrumb links to navigate back to initial view and see the difference in Income.
      </li>
    </ul>
    <project-viewer [config]="{ projectId: appSettings.content.priorityProjectId }"></project-viewer>
    <p>
      This example should be enough as it is to demonstrate the idea how we could control and allocate our resources.
      However, in order to understand the real impact of this index, we should be able to compare the results of this example with the actual one.
      We would like to add this comparison in the future.
    </p>
    <p>
      Alternatively, we could directly list the issues as well, instead of industries; providing clean water, shelter, curing diseases etc.
      The system allows to create such variations as seen in 'Prologue', as long as we can create proper categorization for the organizations.
    </p>
  </div>
</div>
