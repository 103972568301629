<h2>Account Overview</h2>
<div class="form-horizontal">
  <hr />
  <div *ngIf="currentUser">
    <div class="form-group">
      <label class="control-label col-md-3">Email</label>
      <div class="col-md-9">
        <p class="form-control-static">
          {{ currentUser.Email }}
          <span [hidden]="!displayConfirmEmail">
            | (Unconfirmed) <a routerLink="/app/account/confirm-email">Confirm</a>
          </span>
          | <a routerLink="/app/account/change-email">Update</a>
        </p>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-md-3">Username</label>
      <div class="col-md-9">
        <p class="form-control-static">
          {{ currentUser.UserName }}
          | <a routerLink="/app/account/change-username">Update</a>
        </p>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-md-3">Password</label>
      <div class="col-md-9">
        <p class="form-control-static">
          <a [hidden]="!currentUser.HasPassword" routerLink="/app/account/change-password">Change password</a>
          <a [hidden]="currentUser.HasPassword" routerLink="/app/account/add-password">Add password</a>
        </p>
      </div>
    </div>
  </div>
</div>
