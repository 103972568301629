/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/common";
import * as i3 from "./change-email.component";
import * as i4 from "./account.service";
import * as i5 from "@angular/router";
var styles_ChangeEmailComponent = [];
var RenderType_ChangeEmailComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChangeEmailComponent, data: {} });
export { RenderType_ChangeEmailComponent as RenderType_ChangeEmailComponent };
export function View_ChangeEmailComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Change Email"])), (_l()(), i0.ɵeld(2, 0, null, null, 26, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i1.ɵangular_packages_forms_forms_bh, [], null, null), i0.ɵdid(4, 4210688, [["changeEmailForm", 4]], 0, i1.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.NgForm]), i0.ɵdid(6, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 21, "div", [["class", "form-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 13, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "label", [["class", "control-label col-md-3"], ["for", "Email"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Email"])), (_l()(), i0.ɵeld(12, 0, null, null, 10, "div", [["class", "col-md-9"]], null, null, null, null, null)), i0.ɵdid(13, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(14, { "has-error": 0 }), (_l()(), i0.ɵeld(15, 0, null, null, 7, "input", [["class", "form-control"], ["id", "Email"], ["name", "Email"], ["required", ""], ["type", "email"]], [[1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 16)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 16).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 16)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 16)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.bindingModel.Email = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(16, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(17, 16384, null, 0, i1.RequiredValidator, [], { required: [0, "required"] }, null), i0.ɵprd(1024, null, i1.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i1.RequiredValidator]), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(20, 671744, [["Email", 4]], 0, i1.NgModel, [[2, i1.ControlContainer], [6, i1.NG_VALIDATORS], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(22, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵeld(23, 0, null, null, 5, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 4, "div", [["class", "col-md-offset-3 col-md-9"]], null, null, null, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Save "])), (_l()(), i0.ɵeld(27, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Cancel "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = "col-md-9"; var currVal_8 = _ck(_v, 14, 0, (i0.ɵnov(_v, 20).dirty && i0.ɵnov(_v, 20).invalid)); _ck(_v, 13, 0, currVal_7, currVal_8); var currVal_17 = ""; _ck(_v, 17, 0, currVal_17); var currVal_18 = "Email"; var currVal_19 = _co.bindingModel.Email; _ck(_v, 20, 0, currVal_18, currVal_19); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 6).ngClassValid; var currVal_5 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = (i0.ɵnov(_v, 17).required ? "" : null); var currVal_10 = i0.ɵnov(_v, 22).ngClassUntouched; var currVal_11 = i0.ɵnov(_v, 22).ngClassTouched; var currVal_12 = i0.ɵnov(_v, 22).ngClassPristine; var currVal_13 = i0.ɵnov(_v, 22).ngClassDirty; var currVal_14 = i0.ɵnov(_v, 22).ngClassValid; var currVal_15 = i0.ɵnov(_v, 22).ngClassInvalid; var currVal_16 = i0.ɵnov(_v, 22).ngClassPending; _ck(_v, 15, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); var currVal_20 = (i0.ɵnov(_v, 4).form.invalid || _co.isBusy); _ck(_v, 25, 0, currVal_20); var currVal_21 = _co.isBusy; _ck(_v, 27, 0, currVal_21); }); }
export function View_ChangeEmailComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "change-email", [], null, null, null, View_ChangeEmailComponent_0, RenderType_ChangeEmailComponent)), i0.ɵdid(1, 114688, null, 0, i3.ChangeEmailComponent, [i4.AccountService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChangeEmailComponentNgFactory = i0.ɵccf("change-email", i3.ChangeEmailComponent, View_ChangeEmailComponent_Host_0, {}, {}, []);
export { ChangeEmailComponentNgFactory as ChangeEmailComponentNgFactory };
