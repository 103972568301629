import { environment } from "./environments/environment-settings";
var AppSettings = /** @class */ (function () {
    function AppSettings() {
    }
    Object.defineProperty(AppSettings, "environment", {
        /**
         * Name of the current environment
         */
        get: function () { return environment.name; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings, "serviceApiUrl", {
        /**
         * Service application API url
         */
        get: function () { return environment.serviceAppUrl + "/api/v1"; },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(AppSettings, "serviceODataUrl", {
        /**
         * Service application OData url
         */
        get: function () { return environment.serviceAppUrl + "/odata/v1"; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings, "content", {
        /**
         * Content settings
         */
        get: function () { return environment.content; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings, "version", {
        /**
         * Application version number
         */
        get: function () { return "0.89.2"; },
        enumerable: true,
        configurable: true
    });
    return AppSettings;
}());
export { AppSettings };
