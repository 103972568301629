import { OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppSettings } from "../../app-settings/app-settings";
import { AccountService } from "./account.service";
import { Logger } from "../logger/logger.module";
var ChangePasswordComponent = /** @class */ (function () {
    function ChangePasswordComponent(accountService, logger, router) {
        this.accountService = accountService;
        this.logger = logger;
        this.router = router;
        this.bindingModel = {
            CurrentPassword: "",
            NewPassword: "",
            ConfirmPassword: ""
        };
    }
    Object.defineProperty(ChangePasswordComponent.prototype, "isBusy", {
        get: function () {
            return this.accountService.isBusy;
        },
        enumerable: true,
        configurable: true
    });
    ChangePasswordComponent.prototype.cancel = function () {
        this.reset();
        this.router.navigate(["/app/account"]);
    };
    ChangePasswordComponent.prototype.canDeactivate = function () {
        if (this.bindingModel.CurrentPassword === ""
            && this.bindingModel.NewPassword === ""
            && this.bindingModel.ConfirmPassword === "") {
            return true;
        }
        return confirm("Discard changes?");
    };
    ChangePasswordComponent.prototype.changePassword = function () {
        var _this = this;
        this.accountService.changePassword(this.bindingModel)
            .subscribe(function () {
            _this.logger.logSuccess("Your password has been changed!");
            _this.reset();
            _this.router.navigate(["/app/account"]);
        });
    };
    ChangePasswordComponent.prototype.ngOnInit = function () {
        // Generate test data if localhost (only works for the first time :o))
        if (AppSettings.environment === "Development") {
            this.bindingModel.CurrentPassword = "123qwe";
            this.bindingModel.NewPassword = "qwe123";
            this.bindingModel.ConfirmPassword = "qwe123";
        }
    };
    ChangePasswordComponent.prototype.reset = function () {
        this.bindingModel.CurrentPassword = "";
        this.bindingModel.NewPassword = "";
        this.bindingModel.ConfirmPassword = "";
    };
    return ChangePasswordComponent;
}());
export { ChangePasswordComponent };
