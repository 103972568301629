<h2>Change Email</h2>
<form #changeEmailForm="ngForm">
  <div class="form-horizontal">
    <hr />
    <div class="form-group">
      <label for="Email" class="control-label col-md-3">Email</label>
      <div class="col-md-9" [ngClass]="{'has-error': Email.dirty && Email.invalid}">
        <input id="Email" name="Email" type="email" [(ngModel)]="bindingModel.Email" #Email="ngModel" class="form-control" required />
      </div>
    </div>
    <div class="form-group">
      <div class="col-md-offset-3 col-md-9">
        <button (click)="changeEmail()" [disabled]="changeEmailForm.form.invalid || isBusy" type="button" class="btn btn-primary">
          Save
        </button>
        <button (click)="cancel()" [disabled]="isBusy" type="button" class="btn btn-default">
          Cancel
        </button>
      </div>
    </div>
  </div>
</form>
