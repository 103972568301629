import { OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { getUniqueEmail } from "@forcrowd/backbone-client-core";
import { AppSettings } from "../../app-settings/app-settings";
import { AccountService } from "./account.service";
var ChangeEmailComponent = /** @class */ (function () {
    function ChangeEmailComponent(accountService, router) {
        this.accountService = accountService;
        this.router = router;
        this.bindingModel = {
            Email: ""
        };
    }
    Object.defineProperty(ChangeEmailComponent.prototype, "isBusy", {
        get: function () {
            return this.accountService.isBusy;
        },
        enumerable: true,
        configurable: true
    });
    ChangeEmailComponent.prototype.cancel = function () {
        // To be able to pass CanDeactivate
        this.bindingModel.Email = "";
        this.router.navigate(["/app/account"]);
    };
    ChangeEmailComponent.prototype.canDeactivate = function () {
        if (this.bindingModel.Email === "") {
            return true;
        }
        return confirm("Discard changes?");
    };
    ChangeEmailComponent.prototype.changeEmail = function () {
        var _this = this;
        this.accountService.changeEmail(this.bindingModel)
            .subscribe(function () {
            _this.bindingModel.Email = "";
            _this.router.navigate(["/app/account/confirm-email"]);
        });
    };
    ChangeEmailComponent.prototype.ngOnInit = function () {
        // Generate test data if localhost
        if (AppSettings.environment === "Development") {
            this.bindingModel.Email = getUniqueEmail();
        }
    };
    return ChangeEmailComponent;
}());
export { ChangeEmailComponent };
