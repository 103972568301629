<h2>Confirm Email</h2>
<p>
  A confirmation email has been sent to your email address.<br />
  Please use the link in the email to confirm your email address.
</p>
<hr />
<p>
  If you didn't receive a confirmation email, please send it again.<br />
</p>
<p>
  <button (click)="resendConfirmationEmail()" [disabled]="isBusy" type="button" class="btn btn-primary">
    Resend Confirmation Email
  </button>
</p>
