<h2>Register</h2>
<div class="row">
  <div class="col-md-12 g-mt-20">
    <section id="registerForm">
      <form #registerForm="ngForm">
        <div class="form-horizontal">
          <h4>Create a new local account</h4>
          <hr />
          <div class="form-group">
            <label for="UserName" class="control-label col-md-3">Username</label>
            <div class="col-md-9" [ngClass]="{'has-error': UserName.dirty && UserName.invalid}">
              <input id="UserName" name="UserName" type="text" [(ngModel)]="bindingModel.UserName" #UserName="ngModel" class="form-control" aria-describedby="UserNameHelp" required />
              <span id="UserNameHelp" class="help-block">
                No special characters allowed, except dash "-" and underscore "_".
              </span>
            </div>
          </div>
          <div class="form-group">
            <label for="Email" class="control-label col-md-3">Email</label>
            <div class="col-md-9" [ngClass]="{'has-error': Email.dirty && Email.invalid}">
              <input id="Email" name="Email" type="text" [(ngModel)]="bindingModel.Email" #Email="ngModel" class="form-control" required />
            </div>
          </div>
          <div class="form-group">
            <label for="Password" class="control-label col-md-3">Password</label>
            <div class="col-md-9" [ngClass]="{'has-error': Password.dirty && Password.invalid}">
              <input id="Password" name="Password" type="password" [(ngModel)]="bindingModel.Password" #Password="ngModel" class="form-control" required aria-describedby="PasswordHelp" />
              <span id="PasswordHelp" class="help-block">
                Password must be at least 6 characters long,<br />
                must have at least one lowercase ('a'-'z') and one digit ('0'-'9')
              </span>
            </div>
          </div>
          <div class="form-group">
            <label for="ConfirmPassword" class="control-label col-md-3">Confirm</label>
            <div class="col-md-9" [ngClass]="{'has-error': ConfirmPassword.dirty && ConfirmPassword.invalid}">
              <input id="ConfirmPassword" name="ConfirmPassword" type="password" [(ngModel)]="bindingModel.ConfirmPassword" #ConfirmPassword="ngModel" class="form-control" required />
            </div>
          </div>
          <div class="form-group">
            <div class="col-md-offset-3 col-md-9">
              <div class="checkbox">
                <label>
                  <input id="RememberMe" name="RememberMe" type="checkbox" [(ngModel)]="rememberMe"> Remember me
                </label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="col-md-offset-3 col-md-9">
              <button (click)="register()" [disabled]="registerForm.form.invalid || isBusy" type="button" class="btn btn-primary">
                Register
              </button>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</div>
